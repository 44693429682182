import React from 'react';
import Menu from './lis-nav-component';
import SessionSelector from './lis-session-selector';
import Popup from './lis-popup-component';
import Profile from './lis-profile-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import ToastContainer from './lis-toast-component';
import IconLinks from './lis-icon-links-component';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            popupMessage: '',
            isIdle: false,
            isAriaExpanded: false,
            isAriaHidden: true,
            isMenuVisible: false,
            isOpen: false,
            overrideSession: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isIdle && this.props.isIdle) {
            this.setState({
                showPopup: true
            })
        }

        if (this.props.location.pathname === "/home" && prevProps.location.pathname !== "/home" && this.state.overrideSession === "") {
            let session = this.props.session.sessionList.find(x => x.IsDefault);
            if (session) {
                this.props.actions.changeSession(session.SessionCode)
                this.setState({ overrideSession: session })
            }
        } else if (this.state.overrideSession !== "") {
            this.setState({
                overrideSession: ""
            })
        }
    }

    logOutUser() {
        this.props.actions.logoutUser();
    }

    togglePopup() {
        this.setState(state => ({
            showPopup: !state.showPopup
        }));
    }

    slideoutToggle() {
        this.setState(prevState => ({
            isMenuVisible: !prevState.isMenuVisible,
            isAriaHidden: !prevState.isAriaHidden,
            isAriaExpanded: !prevState.isAriaExpanded,
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        const {
            popupMessage
        } = this.props;
        const { isOpen, isAriaHidden, isAriaExpanded, isMenuVisible } = this.state;

        return (
            <div className={isOpen ? 'open-state' : null}>
                {isOpen &&
                    <div className="slideout-overlay" onClick={this.slideoutToggle.bind(this)} />
                }
                <Menu slideoutToggle={this.slideoutToggle.bind(this)} isOpen={this.state.isOpen} session={this.props.session.sessionList.find(x => x.SessionCode === this.props.nav.session)} permissions={this.props.permissions} />
                <Profile
                    isMenuVisible={isMenuVisible}
                    isAriaExpanded={isAriaExpanded}
                    isAriaHidden={isAriaHidden}
                    slideoutToggle={this.slideoutToggle.bind(this)}
                    isOpen={isOpen}
                    slideout={true}
                    selectedSession={this.props.selectedSession}
                    permissions={this.props.permissions}
                />
                <SessionSelector overrideSession={this.state.overrideSession} />
                <ToastContainer toasts={this.props.nav.toasts} />
                <main>
                    <section>
                        <div className="full">
                            <div className="content-wrapper">
                                {this.props.children}
                            </div>
                        </div>
                    </section>
                </main>
                {this.state.showPopup ?
                    <Popup
                        text={popupMessage}
                        showPopup={this.state.showPopup}
                        togglePopup={this.togglePopup.bind(this)}
                        logOut={this.logOutUser.bind(this)}
                        isIdle={this.props.isIdle}
                    />
                    : null
                }
                {this.props.location.pathname != "/" && !this.props.location.pathname.includes('/home')
                    ?
                    null
                    :
                    <IconLinks selectedSession={this.props.session.selectedSession} />
                }
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { login, nav, session } = state;
        return {
            login,
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators, sessionActionCreators), dispatch)
        }
    }
)(Layout)