import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navActionCreators } from '../../stores/lis-nav-store';
import { statActionCreators } from '../../stores/lis-statistics-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { billActionCreators } from '../../stores/lis-legislation-store';

class SessionStatDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            stats: [],
            sessionData: '',
            totals: '',
            billStatusCategoryList: []
        };

        this.setupPage = this.setupPage.bind(this);
    }

    setupPage(sessionCode) {
        this.setState({
            isLoading: true
        }, () => {
            this.props.actions.getBillStatusCategoryReferences()
                .then(() => {
                    let billStatusReferences = [...this.props.bills.billStatusCategoryReferences]
                    billStatusReferences.forEach(ref => {
                        ref.label = ref.Name
                        ref.value = ref.LegislationCategoryID
                    });
                    this.setState({
                        billStatusCategoryList: billStatusReferences,
                    }, () => {
                        this.props.actions.getSessionByCode(sessionCode).then(() => {
                            this.setState({
                                sessionData: this.props.session.selectedSession
                            }, () => {
                                this.props.actions.getChamberStats('?sessionId=' + this.state.sessionData.SessionID)
                                    .then(() => {
                                        let totals = {
                                            ContinuedFromTotal: 0,
                                            IntroducedTotal: 0,
                                            InCommitteeTotal: 0,
                                            PassedHouseTotal: 0,
                                            PassedSenateTotal: 0,
                                            PassedTotal: 0,
                                            IncorporatedTotal: 0,
                                            FailedTotal: 0,
                                            PendingTotal: 0,
                                            ApprovedTotal: 0,
                                            VetoTotal: 0,
                                            ContinuedToTotal: 0
                                        }
                                        this.props.stats.chamberStats.forEach(stat => {
                                            totals.ContinuedFromTotal += stat.ContinuedFromLastSession || 0,
                                                totals.IntroducedTotal += stat.Introduced || 0,
                                                totals.InCommitteeTotal += stat.InCommittee || 0,
                                                totals.PassedHouseTotal += stat.PassedHouse || 0,
                                                totals.PassedSenateTotal += stat.PassedSenate || 0,
                                                totals.PassedTotal += stat.Passed || 0,
                                                totals.IncorporatedTotal += stat.Incorporated || 0,
                                                totals.FailedTotal += stat.Failed || 0,
                                                totals.PendingTotal += stat.Pending || 0,
                                                totals.ApprovedTotal += stat.Approved || 0,
                                                totals.VetoTotal += stat.Veto || 0,
                                                totals.ContinuedToTotal += stat.ContinuedToNextSession || 0
                                        })
                                        this.setState({
                                            stats: this.props.stats.chamberStats,
                                            isLoading: false,
                                            totals: totals
                                        });
                                    }).catch(err => {
                                        if (err === 'Aborted') {
                                            return;
                                        }
                                        this.setState({
                                            isLoading: false,
                                            message: this.props.stats.chamberStatsError
                                        })
                                    });
                            });

                        });
                    });
                })
        });
    }

    componentDidMount() {
        if (this.props.match.params.sessioncode) {
            //Update the session selector component
            if (this.props.nav.session !== this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode)
            }
            this.setupPage(this.props.match.params.sessioncode);
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/statistics/status/')
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            if (this.props.match.params.sessioncode) {
                this.props.actions.changeSession(this.props.match.params.sessioncode);
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return (<div aria-label="Loading" className="center-spinner spinner"></div>)
        }

        const makeLink = (statusCategoryName, currentStatus) => {
            let statusCategoryId;
            if (statusCategoryName) {
                const statusCategory = this.state.billStatusCategoryList.find(category => category.Name.toLowerCase() === statusCategoryName.toLowerCase());
                statusCategoryId = statusCategory ? statusCategory.LegislationCategoryID : 99;
            }

            const params = {
                selectedSession: this.props.session ? this.props.session.SessionID : '',
                selectedBillStatusCategory: statusCategoryId,
                currentStatus: currentStatus || false,
                allLegislation: !statusCategoryName ? true : false,
                uniqueSearch: Math.random()
            };

            const json = JSON.stringify(params);
            const query = window.btoa(json);

            return '/bill-search?q=' + query;
        };

        const sessionHeader = this.state.sessionData ? this.state.sessionData.SessionYear + ' ' + this.state.sessionData.DisplayName + ' Statistics' : '';

        return (
            <div>
                {!this.state.isLoading ?
                    <React.Fragment>
                        <br />
                        <table className="statistics-table">
                            <caption>
                                <h1>{sessionHeader}</h1>
                                <Link to={`/session-details/${this.props.match.params.sessioncode}/statistics/committee/h`}>House Committee Statistics</Link>
                                <span> | </span>
                                <Link to={`/session-details/${this.props.match.params.sessioncode}/statistics/committee/s`}>Senate Committee Statistics</Link>
                            </caption>
                            <tbody>
                                <tr className="homepage-links">
                                    <th>Type</th>
                                    {this.state.totals.ContinuedFromTotal > 0 && <th><Link to={makeLink("Continued from last session", false)}>Continued from Last Session</Link></th>}
                                    <th><Link to={makeLink("Introduced", false)}>Introduced</Link></th>
                                    <th><Link to={makeLink("Passed House", false)}>Passed House</Link></th>
                                    <th><Link to={makeLink("Passed Senate", false)}>Passed Senate</Link></th>
                                    <th><Link to={makeLink("Passed", false)}>Passed</Link></th>
                                    <th><Link to={makeLink("Incorporated", false)}>Incorporated</Link></th>
                                    <th><Link to={makeLink("Failed", false)}>Failed</Link></th>
                                    <th><Link to={makeLink("Pending", true)}>Pending</Link></th>
                                    <th><Link to={makeLink("Approved or Enacted", false)}>Approved</Link></th>
                                    <th><Link to={makeLink("Vetoed", false)}>Vetoed</Link></th>
                                    {this.state.totals.ContinuedToTotal > 0 && <th><Link to={makeLink("Continued to next session", false)}>Continued to Next Session</Link></th>}
                                </tr>
                                {this.state.stats.map(stat =>
                                    <tr>
                                        <td>{stat.ChamberCode}.{stat.LegislationTypeCode}{stat.LegislationTypeCode === "J" && ".R"}</td>
                                        {this.state.totals.ContinuedFromTotal > 0 && <td>{stat.ContinuedFromLastSession}</td>}
                                        <td>{stat.Introduced}</td>
                                        <td>{stat.PassedHouse}</td>
                                        <td>{stat.PassedSenate}</td>
                                        <td>{stat.Passed}</td>
                                        <td>{stat.Incorporated}</td>
                                        <td>{stat.Failed}</td>
                                        <td>{stat.Pending}</td>
                                        <td>{stat.Approved}</td>
                                        <td>{stat.Veto}</td>
                                        {this.state.totals.ContinuedToTotal > 0 && <td>{stat.ContinuedToNextSession}</td>}
                                    </tr>
                                )}
                                <tr>
                                    <td>Totals</td>
                                    {this.state.totals.ContinuedFromTotal > 0 && <td>{this.state.totals.ContinuedFromTotal}</td>}
                                    <td>{this.state.totals.IntroducedTotal}</td>
                                    <td>{this.state.totals.PassedHouseTotal}</td>
                                    <td>{this.state.totals.PassedSenateTotal}</td>
                                    <td>{this.state.totals.PassedTotal}</td>
                                    <td>{this.state.totals.IncorporatedTotal}</td>
                                    <td>{this.state.totals.FailedTotal}</td>
                                    <td>{this.state.totals.PendingTotal}</td>
                                    <td>{this.state.totals.ApprovedTotal}</td>
                                    <td>{this.state.totals.VetoTotal}</td>
                                    {this.state.totals.ContinuedToTotal > 0 && <td>{this.state.totals.ContinuedToTotal}</td>}
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                    : <div aria-label="Loading" className="center-spinner spinner" />
                }
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { stats, nav, session, bills } = state;
        return {
            stats,
            nav,
            session,
            bills
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, statActionCreators, navActionCreators, sessionActionCreators, billActionCreators), dispatch)
        }
    }
)(SessionStatDetails)
