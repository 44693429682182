import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../stores/lis-committee-store';
import { navActionCreators } from '../../stores/lis-nav-store';
import { scheduleActionCreators } from '../../stores/lis-schedule-store';
import { sessionActionCreators } from '../../stores/lis-session-store';
import MeetingsForm from '../../lis-shared/lis-meetings/lis-meetings-form-component';
import MeetingsCalendarPreview from '../../lis-shared/lis-meetings/lis-meetings-calendar-preview';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { cancelRequest } from '../../services/request.service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import renderHTML from 'react-render-html';
import { generateCsv } from '../../services/csv.service';

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: '0',
    background: isDragging ? "white" : "white",
    borderBottom: '1px solid #b3b3b3',
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const removed = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed[0]);

    return result;
};

const SCHEDULE_EDITOR = "Schedule";
const SENATE_SCHEDULE = "SenateScheduleAuthor";

class PublicSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organizedSchedule: [],
            currentDayIndex: 0,
            locationOptions: [],
            showAllDates: true,
            isEditing: false,
            meeting: {},
            committeeList: [],
            fullCommitteeList: [],
            partner: false,
            isSaving: false,
            scheduleTypeList: [],
            startDate: moment(),
            endDate: null,
            keyword: '',
            location: [],
            calendarList: [],
            calendarDisabled: true,
            displayScheduleModal: false,
            displayMeetingsCalendarPreview: false,
            pdfSrc: "",
            gettingPreview: false,
            isDirty: false,
            showConfirmation: false
        }

        this.toggleAllDates = this.toggleAllDates.bind(this);
        this.toggleEditMeeting = this.toggleEditMeeting.bind(this);
        this.changeDay = this.changeDay.bind(this);
        this.getCommittees = this.getCommittees.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.openMeeting = this.openMeeting.bind(this);
        this.saveMeetings = this.saveMeetings.bind(this);
        this.createMeeting = this.createMeeting.bind(this);
        this.toggleCancelMeeting = this.toggleCancelMeeting.bind(this);
        this.deleteMeeting = this.deleteMeeting.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.openScheduleModal = this.openScheduleModal.bind(this);
        this.closeScheduleModal = this.closeScheduleModal.bind(this);
        this.toggleMeetingsCalendarPreview = this.toggleMeetingsCalendarPreview.bind(this);
        this.getPreview = this.getPreview.bind(this);
        this.setFormIsDirty = this.setFormIsDirty.bind(this);
        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    changeDay(amount) {
        let currentDayIndex = this.state.currentDayIndex += amount;
        this.setState({
            currentDayIndex: currentDayIndex
        });
    }

    toggleAllDates() {
        this.setState(state => ({
            showAllDates: !state.showAllDates
        }));
    }

    toggleEditMeeting(confirmed) {
        if (this.state.isDirty && !confirmed) {
            this.setState({
                showConfirmation: true
            })
        } else {
            this.setState(state => ({
                isEditing: !state.isEditing,
                meeting: { ScheduleID: 0 },
                isDirty: false,
                showConfirmation: false
            }))
        }
    }

    getCommittees(sessionParam) {
        this.props.actions.getCommitteeList(sessionParam + "&includeSubCommittees=true").then(() => {
            let committeeList = [...this.props.committee.committeeList];
            committeeList.forEach(committee => {
                committee.label = committee.Name;
                committee.value = committee.CommitteeID;
            });

            //create a hierarchical list of committees (groups of committees, each of which contains the parent committee and its subcommittees) for the committee dropdown
            const committeeDropDownOptions = JSON.parse(JSON.stringify(committeeList)).filter(cmte => !cmte.ParentCommitteeID).map(cmte => {
                //add the chamber designator to the group header if necessary
                cmte.label = cmte.label += (this.props.login.userClaims.claims.find(x => x.RoleName === "Admin") || (this.props.login.userClaims.claims.find(x => x.RoleName === "HouseScheduleEditor") && this.props.login.userClaims.claims.find(x => x.RoleName === "SenateScheduleEditor")) ? (' ' + '(' + cmte.ChamberCode + ')') : "");
                //since the header for each group of committees will already contain the chamber code if necessary, the individual options therein do not need it
                const parentCmteOption = { ...cmte, label: cmte.label && (cmte.label.endsWith(' (H)') || cmte.label.endsWith(' (S)')) ? cmte.label.substr(0, cmte.label.length - 4) : cmte.label }
                cmte.options = [parentCmteOption].concat([...committeeList].filter(sub => sub.ParentCommitteeID === cmte.CommitteeID));

                return cmte;
            })

            this.setState({
                fullCommitteeList: committeeList,
                committeeList: committeeList.filter(committee => {
                    if (this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
                        return true;
                    } else if (committee.ChamberCode === "H" && this.props.login.userClaims.claims.find(x => x.RoleName === "HouseScheduleEditor") ||
                        committee.ChamberCode === "S" && this.props.login.userClaims.claims.find(x => x.RoleName === "SenateScheduleEditor")) {
                        return true;
                    }
                    return false;
                }),
                committeeDropDownOptions: committeeDropDownOptions.filter(committee => {
                    if (this.props.login.userClaims.claims.find(x => x.RoleName === "Admin")) {
                        return true;
                    } else if (committee.ChamberCode === "H" && this.props.login.userClaims.claims.find(x => x.RoleName === "HouseScheduleEditor") ||
                        committee.ChamberCode === "S" && this.props.login.userClaims.claims.find(x => x.RoleName === "SenateScheduleEditor")) {
                        return true;
                    }
                    return false;
                }),
            });
        });
    }

    handleChange(value, state) {
        this.setState({
            [state]: value
        })
    }

    handleSearch(preserveIndex) {
        const startDate = moment(this.state.startDate).hour(0).minute(0).second(0).format('MM/DD/YYYY HH:mm:ss');
        const endDate = this.state.endDate ? moment(this.state.endDate).hour(23).minute(59).second(59).format('MM/DD/YYYY HH:mm:ss') : null;
        const voteRooms = this.state.location.length > 0 ? this.state.location : null;

        let keyword = this.state.keyword;

        this.setState({
            isLoaded: false,
            isEditing: false
        }, () => {
            let params = "startDate=" + startDate;
            if (endDate) {
                params += "&endDate=" + endDate;
            }

            this.props.actions.getScheduleList(params, this.state.partner)
                .then(() => {
                    if (this.props.schedule.scheduleListError) {
                        throw this.props.schedule.scheduleListError;
                    }
                    let scheduleList = this.props.schedule.scheduleList
                    // Filter out the results that are not part of the search
                    if (keyword) {
                        //Get rid of white space and make sure its all in lowercase so elements that are close to the search are included as well
                        keyword = keyword.replace(/\s/g, '');
                        keyword = keyword.toLowerCase();
                        scheduleList = scheduleList.filter(item => {
                            const roomDescription = item.RoomDescription ? item.RoomDescription.replace(/\s/g, '') : '';
                            const comments = item.Comments ? item.Comments.replace(/\s/g, '') : '';
                            const time = item.ScheduleTime ? item.ScheduleTime.replace(/\s/g, '') : '';
                            const ownerName = item.OwnerName ? item.OwnerName.replace(/\s/g, '') : '';
                            if (roomDescription.toLowerCase().includes(keyword) ||
                                comments.toLowerCase().includes(keyword) ||
                                time.toLowerCase().includes(keyword) ||
                                ownerName.toLowerCase().includes(keyword)) {
                                return item;
                            }
                        });
                    }
                    if (voteRooms) {
                        scheduleList = scheduleList.filter(item => {
                            if (voteRooms.find(room => room.VoteRoomID === item.VoteRoomID)) {
                                return (item);
                            }
                        });
                    }
                    if (scheduleList.length === 0) {
                        this.setState({
                            isLoaded: true
                        })
                    }
                    const organizedSchedule = this.organizeSchedule(scheduleList)
                    this.setState({
                        isLoaded: true,
                        organizedSchedule: organizedSchedule,
                        currentDayIndex: preserveIndex ? this.state.currentDayIndex : 0,
                        scheduleError: null,
                        calendarList: scheduleList
                    });
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.setState({
                        isLoaded: true,
                        scheduleError: err
                    });
                });
        });
    }

    handleReset() {
        this.setState({
            location: [],
            keyword: '',
            startDate: moment(),
            endDate: null
        }, () => {
            this.handleSearch();
        })
    }

    organizeSchedule(scheduleList) {
        // Turn the list of schedule items into a list of dates each containing a list of schedule items
        // This formatting is done so the schedule can be listed in the table with a date as a container
        scheduleList.sort((a, b) => {
            return new Date(a.ScheduleDate) - new Date(b.ScheduleDate);
        });
        let organizedSchedule = [];
        let scheduleArray = [];
        let previousSchedule = scheduleList[0];
        scheduleList.forEach(schedule => {
            if (moment(previousSchedule.ScheduleDate).format('L') !== moment(schedule.ScheduleDate).format('L')) {
                //Add the list of legislation to the category
                //Organize the times so that the hour/minute times are in their seperate containers, this is done to help with formatting
                let newDateContainer = {
                    Times: this.organizeTimes(scheduleArray),
                    Date: previousSchedule.ScheduleDate,
                };
                organizedSchedule.push(newDateContainer);
                //Empty out the leg array so we can use it for another category
                scheduleArray = [];
                //Add the first item to the next category
                scheduleArray.push(schedule);
            } else {
                scheduleArray.push(schedule);
            }
            //Set this variable so the next iteration will know what the schedule date of the previous iteration was
            previousSchedule = schedule;
        });
        //If the schedule array still has elements then that means not every schedule item in the results has been added to a container
        //So, check to see if the schedule array has elements and if so create another container
        if (scheduleArray.length > 0) {
            let newDateContainer = {
                Times: this.organizeTimes(scheduleArray),
                Date: previousSchedule.ScheduleDate,
            };
            organizedSchedule.push(newDateContainer);
        }

        return organizedSchedule;
    }

    organizeTimes(itemsList) {
        //Sort on the ScheduleTimes -- if there is no ScheduleTime, use the ScheduleDate, though the times extracted from ScheduleDate will not display in the UI
        itemsList.sort((a, b) => {
            let timeA;
            let timeB;
            if (a.ScheduleTime && moment(a.ScheduleTime, "h:mm A").isValid()) {
                const date = new Date(a.ScheduleDate);
                timeA = moment(a.ScheduleTime, "h:mm A").year(date.getFullYear()).month(date.getMonth()).date(date.getDate());
            }
            if (b.ScheduleTime && moment(b.ScheduleTime, "h:mm A").isValid()) {
                const date = new Date(b.ScheduleDate);
                timeB = moment(b.ScheduleTime, "h:mm A").year(date.getFullYear()).month(date.getMonth()).date(date.getDate());
            }
            if (!timeA || !timeB) {
                return a.DisplaySequence && b.DisplaySequence && a.DisplaySequence - b.DisplaySequence !== 0 ?
                    a.DisplaySequence - b.DisplaySequence :
                    moment(a.ScheduleDate).isAfter(b.ScheduleDate) ? 1 :
                        moment(a.ScheduleDate).isSame(b.ScheduleDate) ? b.ScheduleTime ? 1 : -1 : -1
            }
            return moment(timeA).isAfter(timeB) ? 1 : moment(timeA).isSame(timeB) ? b.ScheduleTime ? 1 : -1 : -1
        })

        let organizedTimes = [];
        let items = [];
        let time = "";

        for (let i = 0; i < itemsList.length; i++) {
            //If a ScheduleTime has been specified then use that, otherwise get the time from the ScheduleDate but do not display it in the list
            let currentTime = itemsList[i].ScheduleTime && moment(itemsList[i].ScheduleTime, "h:mm A").isValid() ? itemsList[i].ScheduleTime : "";

            //If we've entered a new time then push the current time and sorted items into organizedTimes and reset variables
            if (currentTime !== time) {
                if (items.length > 0) {
                    items.sort((a, b) => a.DisplaySequence - b.DisplaySequence);
                    organizedTimes.push({
                        Items: items,
                        Time: time
                    });
                }

                time = currentTime;
                items = [itemsList[i]];
            } else {
                items.push(itemsList[i]);
            }
        }

        //Sort and push the last time and sorted items
        items.sort((a, b) => a.DisplaySequence - b.DisplaySequence);
        organizedTimes.push({
            Items: items,
            Time: time
        })

        return organizedTimes;
    }

    openMeeting(meeting) {
        this.setState({
            meeting: meeting,
            isEditing: true,
            isDirty: false
        }, () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        })
    }

    saveMeetings(meetings, callback) {
        this.setState({
            isSaving: true
        }, () => {
            this.props.actions.saveSchedule(meetings)
                .then((result) => {
                    this.props.actions.makeToast([{ message: "Schedule Saved", type: "success" }]);
                    this.setState({
                        isSaving: false,
                        showPopup: false,
                        isDirty: false
                    }, () => {
                        //Refresh the data
                        this.handleSearch(true);
                        if (callback)
                            callback();
                    })
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false,
                    });
                });
        })
    }

    createMeeting(meeting) {
        this.setState({
            isSaving: true
        }, () => {
            this.props.actions.createSchedule(meeting)
                .then((response) => {
                    this.props.actions.makeToast([{ message: "Schedule Saved", type: "success" }]);
                    this.setState({
                        isSaving: false,
                        isEditing: false,
                        isDirty: false
                    }, () => {
                        this.handleSearch();
                    })
                })
                .catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    this.props.actions.makeToast([{ message: "Save Failed", type: "failure" }]);
                    this.setState({
                        isSaving: false,
                    });
                });
        })
    }

    toggleCancelMeeting(meeting) {
        meeting.IsCancelled = !meeting.IsCancelled
        let params = {
            Schedules: [
                meeting
            ]
        };
        this.saveMeetings(params);
    }

    deleteMeeting(meeting, onFail) {
        let promises = [];
        meeting.ScheduleFiles?.forEach(scheduleFile => {
            promises.push(this.handleFileChange(null, scheduleFile, true, null, meeting));
        });
        Promise.all(promises).then(() => {
            meeting.DeletionDate = moment();
            let params = {
                Schedules: [
                    meeting
                ]
            };
            this.saveMeetings(params);
        }).catch(() => {
            if (onFail) onFail();
        })
    }

    async handleFileChange(e, originalFile, isDelete, callback, deleteMeeting) {
        if (isDelete || e.target.files[0]) {
            let meeting = deleteMeeting ?? { ...this.state.meeting };
            let originalFileIdx = -1;
            let params = '?scheduleID=' + meeting.ScheduleID;
            if (isDelete) params += "&IsDeleted=true";
            if (originalFile) params += "&FileURL=" + originalFile.FileURL + "&FileID=" + originalFile.FileID;
            await this.props.actions.uploadMeetingPdf(params, isDelete ? null : e.target.files[0]).then(() => {
                const res = this.props.schedule.uploadMeetingPdf;
                let update = false;
                if (originalFile) {
                    originalFileIdx = meeting.ScheduleFiles.findIndex(f => f.FileURL === originalFile.FileURL);
                    if (originalFileIdx > -1) {
                        if (isDelete) {
                            meeting.ScheduleFiles.splice(originalFileIdx, 1);
                        } else {
                            meeting.ScheduleFiles[originalFileIdx] = res;
                            update = true;
                        }
                    } else if (!isDelete) {
                        meeting.ScheduleFiles.push(res);
                    }
                } else if (!isDelete) {
                    meeting.ScheduleFiles.push(res);
                }
                this.setState({
                    meeting: deleteMeeting ? this.state.meeting : meeting
                }, () => {
                    if (callback) callback(meeting.ScheduleFiles, update, originalFileIdx, res.FileURL)
                })
            }).catch(err => {
                if (err === 'Aborted') {
                    return;
                }
                console.log(err);
                this.props.actions.makeToast([{ message: isDelete ? "PDF Delete Failed" : "PDF Upload Failed", type: "failure" }]);
                return Promise.reject();
            })
        }
    }

    onDragEnd(date, result) {
        if (!result.destination) {
            return;
        }

        //Exit if there is no change
        if (result.destination.index === result.source.index) {
            return;
        }

        let scheduleDate = JSON.parse(JSON.stringify(this.state.organizedSchedule.find(x => x.Date === date)));
        let flatMappedSchedules = scheduleDate.Times.flatMap(t => t.Items);
        let [removed] = flatMappedSchedules.splice(result.source.index, 1);
        flatMappedSchedules.splice(result.destination.index, 0, removed);

        //Exit if meetings have been moved out of timely order
        let outOfOrder;
        flatMappedSchedules.filter(i => i.ScheduleTime && moment(i.ScheduleTime, "h:mm A").isValid()).map(i => i.ScheduleTime).every((time, idx, arr) => {
            const returnValue = !idx || moment(arr[idx - 1], "h:mm A").isSameOrBefore(moment(time, "h:mm A"));
            if (!returnValue) {
                outOfOrder = arr[idx - 1] + ' cannot be sequenced before ' + time;
            }
            return returnValue;
        })
        if (outOfOrder) {
            this.props.actions.makeToast([{ message: outOfOrder, type: "failure" }]);
            return;
        }

        // Reorder the DisplaySequence value too!
        flatMappedSchedules.forEach((element, index) => {
            element.DisplaySequence = index + 1
        });

        const organizedDate = this.organizeSchedule(flatMappedSchedules);
        const scheduleDateItems = organizedDate[0].Times.flatMap(t => t.Items);

        let params = {
            Schedules: scheduleDateItems
        }

        this.setState({
            isSavingDrag: date
        }, () => {
            this.saveMeetings(params, () => {
                this.setState({ isSavingDrag: false })
            });
        })
    }

    fetchCsv(showDates) {
        let rows = [];
        let meetingDate, meetingTime, lastDate, lastTime;

        showDates.forEach(date => {
            date.Times.forEach(time => {
                time.Items.forEach(item => {
                    //Leave Date and/or Time blank if previous meeting takes place at the same date and/or time
                    let dateDate = moment(date.Date).format("MM/DD/YYYY");
                    meetingDate = !moment(dateDate).isSame(lastDate, 'day') ? dateDate : "";
                    meetingTime = time.Time !== lastTime ? time.Time : "";

                    rows.push({ Date: meetingDate, Time: item.IsCancelled ? "Cancelled" : meetingTime, Meeting: item.OwnerName, Location: item.RoomDescription });

                    lastDate = moment(item.ScheduleDate).format("MM/DD/YYYY");
                    lastTime = item.ScheduleTime;
                })
            });
        })

        let fileName = showDates.length > 1 ? showDates[0].Date + "-" + showDates[showDates.length - 1].Date + "_Schedule.csv" : showDates[0].Date + "_Schedule.csv"

        saveAs(new Blob([generateCsv(["Date", "Time", "Meeting", "Location"], rows)], { type: "data:text/csv;charset=utf-8," }), fileName);
    }

    toggleRow(scheduleID) {
        let calendarList = [...this.state.calendarList];
        let calendarItem = calendarList.find(x => x.ScheduleID === scheduleID);

        calendarItem.checked = !calendarItem.checked;

        calendarList.splice(calendarList.findIndex(x => x.ScheduleID === calendarItem.ScheduleID), 1, calendarItem);

        this.setState({
            calendarList: calendarList
        });
    }

    openScheduleModal() {
        this.setState({
            displayScheduleModal: true
        })
    }

    closeScheduleModal() {
        this.setState({
            displayScheduleModal: false
        })
    }

    toggleMeetingsCalendarPreview() {
        this.setState({
            displayMeetingsCalendarPreview: !this.state.displayMeetingsCalendarPreview
        })
    }

    getPreview(date) {
        this.setState({ gettingPreview: true }, () => {
            this.props.actions.getMeetingsCalendarPreview("?startDate=" + moment(date).format("MM/DD/YYYY"))
                .then(() => {
                    if (this.props.schedule.meetingsCalendarPreview !== "") {
                        this.props.schedule.meetingsCalendarPreview.body.getReader().read().then(({ value }) => {
                            const blob = new Blob([value], { type: "application/pdf" });
                            const urlObj = URL.createObjectURL(blob);
                            this.setState({
                                pdfSrc: urlObj,
                                gettingPreview: false
                            });
                        }).catch(() => {
                            this.setState({ gettingPreview: false })
                        })
                    } else {
                        this.setState({ gettingPreview: false })
                    }
                }).catch(() => {
                    this.setState({ gettingPreview: false })
                })
        })
    }

    componentDidMount() {
        this.setState({
            partner: Boolean(this.props.login.userClaims.resources.find(resource => resource === SCHEDULE_EDITOR))
        })

        this.props.actions.getScheduleTypeList().then(() => {
            this.setState({
                scheduleTypeList: Array.from(this.props.schedule.scheduleTypeList, obj => {
                    const rObj = {
                        ...obj,
                        value: obj.ScheduleTypeID,
                        label: obj.ScheduleType
                    };
                    return rObj;
                })
            })
        })

        this.props.actions.getMeetingRoomReferences('').then(() => {
            const meetingRoomsList = Array.from(this.props.schedule.meetingRoomReferences, obj => {
                const rObj = {
                    ...obj,
                    value: obj.VoteRoomID,
                    label: obj.Description + ' ' + (obj.RoomNumber || '')
                };
                return rObj;
            });
            this.setState({
                locationOptions: meetingRoomsList
            });
        })

        this.props.actions.getSessionList()
            .then(() => {
                let selectedSession = '';
                this.props.session.sessionList.forEach(session => {
                    if (session.SessionCode === this.props.nav.session) {
                        selectedSession = session;
                    }
                });

                if (!selectedSession) {
                    selectedSession = this.props.session.sessionList.find(s => s.IsDefault);
                }

                this.setState({
                    sessionOptions: this.props.session.sessionList,
                    selectedSession: selectedSession.SessionID
                });
                const sessionParam = "sessionID=" + selectedSession.SessionID;

                this.getCommittees(sessionParam);
            });

        this.handleSearch();
    }

    setFormIsDirty(value) {
        this.setState({
            isDirty: value
        })
    }

    closeConfirmation() {
        this.setState({
            showConfirmation: false
        })
    }

    componentWillUnmount() {
        cancelRequest();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.login.userClaims.resources) !== JSON.stringify(this.props.login.userClaims.resources)) {
            //Disable/Remove management controls if user logs out or otherwise loses Schedule role
            const isPartner = Boolean(this.props.login.userClaims.resources.find(resource => resource === SCHEDULE_EDITOR));
            this.setState({ partner: isPartner }, () => {
                this.handleSearch();
            })
        }
    }

    render() {
        const { isLoaded, organizedSchedule, currentDayIndex, locationOptions, showAllDates, committeeList, scheduleTypeList, meeting, isEditing, partner, isSaving, isSavingDrag, startDate, endDate, keyword, location, displayScheduleModal, displayMeetingsCalendarPreview } = this.state
        //If the option to show all is selected then this array will contain every date. If the option to show one then this array will only contain one item
        const showDates = showAllDates ? organizedSchedule : [organizedSchedule[currentDayIndex]];
        return (
            <div id="schedule-page">
                {this.state.showConfirmation &&
                    <div className="schedule-modal">
                        <div className="schedule-modal-content">
                            <p>You have unsaved changes. Are you sure you want to close the form?</p>
                            <button className="button secondary float-right" onClick={() => this.closeConfirmation()}>No</button>
                            <button className="button primary float-right" onClick={() => this.toggleEditMeeting(true)} style={{ marginRight: "10px" }}>Yes</button>
                            <br />
                        </div>
                    </div>
                }
                {displayMeetingsCalendarPreview ?
                    <MeetingsCalendarPreview
                        toggleMeetingsCalendarPreview={this.toggleMeetingsCalendarPreview}
                        pdfSrc={this.state.pdfSrc}
                        getPreview={this.getPreview}
                        gettingPreview={this.state.gettingPreview}
                    />
                    :
                    <React.Fragment>
                        <br />
                        {displayScheduleModal &&
                            <ScheduleModal
                                closeScheduleModal={this.closeScheduleModal}
                            />
                        }
                        <div id="schedule-table">
                            <div className="inner-grid one-and-three no-margin">
                                <EventFinder
                                    organizedSchedule={organizedSchedule}
                                    handleChange={this.handleChange}
                                    handleSearch={this.handleSearch}
                                    handleReset={this.handleReset}
                                    locationOptions={locationOptions}
                                    isLoaded={isLoaded}
                                    startDate={startDate}
                                    endDate={endDate}
                                    keyword={keyword}
                                    location={location}
                                />
                                {isLoaded ?
                                    <div>
                                        <div className="section-side-padding">
                                            <div className="header-half">
                                                <h2 style={{ marginBlockStart: '0px' }}>Schedule of Events</h2>
                                                <div className="align-right meeting-actions">
                                                    {this.props.login.userClaims.resources.find(resource => resource === SCHEDULE_EDITOR) &&
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            style={{ marginRight: "10px", width: "188px", padding: "8px 5px" }}
                                                            onClick={this.toggleMeetingsCalendarPreview}
                                                        >Meeting Calendar Preview</button>
                                                    }
                                                    {partner &&
                                                        <button
                                                            type="button"
                                                            className="button"
                                                            style={{ marginRight: "10px" }}
                                                            onClick={this.toggleEditMeeting}
                                                        >Add Meeting</button>
                                                    }
                                                    <button className="button-link" onClick={this.toggleAllDates}>{showAllDates ? "Show Day" : "Show All"}</button>
                                                    {showDates[0] != undefined &&
                                                        <React.Fragment>
                                                            <a target="_blank" onClick={() => this.fetchCsv(showDates)} style={{ marginLeft: "10px" }}>
                                                                <span title="Download events as a spreadsheet" aria-label="Download events as a spreadsheet" role="button" className="icon csv"></span>
                                                            </a>
                                                            <button title="Add selected events to your calendar" role="button" aria-label="Add selected events to your calendar" className="icon calendar" /* target="_blank" */ style={{ marginLeft: "10px" }} onClick={() => this.openScheduleModal()} />
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {isEditing && partner &&
                                            <MeetingsForm
                                                meeting={meeting}
                                                committeeList={committeeList}
                                                committeeDropDownOptions={this.state.committeeDropDownOptions || []}
                                                locationList={locationOptions}
                                                scheduleTypeList={scheduleTypeList}
                                                isSaving={isSaving}
                                                saveMeetings={this.saveMeetings}
                                                createMeeting={this.createMeeting}
                                                toggleEditMeeting={this.toggleEditMeeting}
                                                userClaims={this.props.login.userClaims}
                                                getSubcommitteeMembers={this.props.actions.getSubcommitteeMembers}
                                                subcommitteeMembers={this.props.schedule.subcommitteeMembers}
                                                setFormIsDirty={this.setFormIsDirty}
                                                isDirty={this.state.isDirty}
                                                handleFileChange={this.handleFileChange}
                                            />}
                                        {this.state.scheduleError ?
                                            <div className="center">
                                                <p>{this.state.scheduleError}</p>
                                            </div>
                                            :
                                            <div className="schedule-content-wrapper">
                                                {showDates[0] != undefined
                                                    ? showDates.map((currentDay, keyIndex) =>
                                                        <div key={keyIndex}>
                                                            <div className="day-wrapper">
                                                                {!showAllDates &&
                                                                    <React.Fragment>
                                                                        <div style={{ float: 'left' }}>
                                                                            {organizedSchedule[currentDayIndex - 1] &&
                                                                                <button aria-label="Previous day" title="Previous day" type="button" onClick={() => this.changeDay(-1)} className="icon button-left"></button>
                                                                            }
                                                                        </div>
                                                                        <div style={{ float: 'right' }}>
                                                                            {organizedSchedule[currentDayIndex + 1] &&
                                                                                <button aria-label="Next day" title="Next day" type="button" onClick={() => this.changeDay(1)} className="icon button-right"></button>
                                                                            }
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                <div className="center mobile-left">
                                                                    <h4>{moment(currentDay.Date).format('dddd, MMMM Do, YYYY')}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="section-side-padding">
                                                                <div className="side-form">
                                                                    {partner ?
                                                                        <PartnerSchedule
                                                                            currentDay={currentDay}
                                                                            session={this.props.nav.session}
                                                                            openMeeting={this.openMeeting}
                                                                            deleteMeeting={this.deleteMeeting}
                                                                            toggleCancelMeeting={this.toggleCancelMeeting}
                                                                            onDragEnd={this.onDragEnd}
                                                                            isSaving={isSaving}
                                                                            isSavingDrag={isSavingDrag}
                                                                            toggleRow={this.toggleRow}
                                                                            calendarList={this.state.calendarList}
                                                                            fullCommitteeList={this.state.fullCommitteeList}
                                                                        />
                                                                        :
                                                                        <Schedule
                                                                            currentDay={currentDay}
                                                                            session={this.props.nav.session}
                                                                            toggleRow={this.toggleRow}
                                                                            calendarList={this.state.calendarList}
                                                                            fullCommitteeList={this.state.fullCommitteeList}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <p style={{ textAlign: "center" }}>Nothing Scheduled</p>
                                                }
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="center-spinner spinner">Loading...</div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>

        );
    }
}

class EventFinder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSection: window.innerWidth <= 900 ? false : true //900px is when mobile layout is used (set in mobile.scss); collapse by default
        };
        this.toggleSection = this.toggleSection.bind(this);
        this.searchOnEnterPress = this.searchOnEnterPress.bind(this);
    }

    searchOnEnterPress(event) {
        if (event.key === 'Enter' && this.props.isLoaded) {
            //Pressing enter while on a react-select will select an option. A search should not happen because of that.
            if (!event.target.id.includes('react-select') && !event.target.className.includes('quick-search') && !event.target.id.includes('description')) {
                this.props.handleSearch();
            }
        }
    }

    componentDidMount() {
        document.querySelector('body').addEventListener('keydown', this.searchOnEnterPress);
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener('keydown', this.searchOnEnterPress);
    }

    toggleSection() {
        this.setState(state => ({
            showSection: !state.showSection
        }));
    }

    render() {
        const { locationOptions, handleChange, handleSearch, handleReset, startDate, endDate, keyword, location } = this.props;

        const customStyles = {
            option: (base, state) => ({
                ...base,
                fontSize: '0.8em',
            }),
            control: (base) => ({
                ...base,
                padding: '1px',
                margin: 0,
                minHeight: 0,
                fontSize: '0.8em',
            }),
            singleValue: (base, state) => {
                return { ...base, };
            },
            dropdownIndicator: base => ({
                ...base,
                padding: '0px 8px'
            })
        }

        return (
            <div className="section-side-padding" style={{ paddingBottom: '0' }}>
                <div className="dlas-forms side-form event-finder">
                    <div className="header flex-row flex-vertical-center">
                        <h3 className="center" style={{ margin: 'auto' }}>Find an Event</h3>
                        <button onClick={this.toggleSection} type="button" className={this.state.showSection ? "arrow-up white-chevron" : "arrow-down white-chevron"}></button>
                    </div>
                    {this.state.showSection &&
                        <div className="content">
                            <div>
                                <div>
                                    <label htmlFor="start-date-datepicker">Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(val) => handleChange(val, "startDate")}
                                        id="start-date-datepicker"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="end-date-datepicker">End Date</label>
                                    <DatePicker
                                        isClearable
                                        selected={endDate}
                                        onChange={(val) => handleChange(val, "endDate")}
                                        id="end-date-datepicker"
                                        todayButton="Today"
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="keyword-input">Keyword</label>
                                    <div>
                                        <input
                                            type="text"
                                            id="keyword-input"
                                            value={keyword}
                                            onChange={(e) => handleChange(e.target.value, "keyword")}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="location-dropdown">Location</label>
                                    <Select
                                        id="location-dropdown"
                                        value={location}
                                        options={locationOptions}
                                        isMulti
                                        onChange={(val) => handleChange(val || [], "location")}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="center flex-row">
                                <button onClick={handleReset} type="button" className="button secondary">Clear</button>
                                <button onClick={handleSearch} type="button" className="button">Search</button>
                            </div>
                        </div>
                    }
                </div>
            </div>);
    }
}

class PartnerSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            selectedMeeting: {}
        }

        this.togglePopup = this.togglePopup.bind(this)
    }

    togglePopup(selectedMeeting) {
        this.setState({
            showPopup: !this.state.showPopup,
            selectedMeeting: selectedMeeting
        });
    }

    render() {
        const { session, currentDay, openMeeting, deleteMeeting, toggleCancelMeeting, onDragEnd, isSaving, isSavingDrag, toggleRow, calendarList, fullCommitteeList } = this.props;
        return (
            <React.Fragment>
                {this.state.showPopup &&
                    <div className='popup'>
                        <div className='popup-inner'>
                            <p>Are you sure you want to delete this meeting?</p>
                            <div className="inline-list">
                                <button className="button danger" disabled={isSaving} onClick={() => { deleteMeeting(this.state.selectedMeeting, () => { this.togglePopup({}) }) }}>Yes</button>
                                <button className="button" disabled={isSaving} onClick={() => { this.togglePopup({}) }}>No</button>
                            </div>
                        </div>
                    </div>}
                <DragDropContext onDragEnd={result => onDragEnd(currentDay.Date, result)}>
                    <Droppable droppableId={currentDay.Date} index={currentDay.Date}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {isSavingDrag && isSavingDrag.toString() === currentDay.Date.toString() ? <div className="spinner" /> :
                                    currentDay.Times.map((time, timeIndex) =>
                                        time.Items.map((item, itemIndex) =>
                                            !item.DeletionDate &&
                                            <Draggable key={!timeIndex ? itemIndex : currentDay.Times.filter((t, idx) => idx < timeIndex).flatMap(t => t.Items).length + itemIndex} draggableId={item.ScheduleID.toString() || (currentDay.Date + '|' + time + '|' + itemIndex)} index={!timeIndex ? itemIndex : currentDay.Times.filter((t, idx) => idx < timeIndex).flatMap(t => t.Items).length + itemIndex}>
                                                {(provided, snapshot) =>
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        className="inner-grid no-grid-column-gap two-col-sidebar-backwards schedule-event"
                                                    >
                                                        <div className="time-wrapper">
                                                            {time.Time && <span className="txt-greyed">{time.Time}</span>}
                                                        </div>
                                                        <div className="items-wrapper">
                                                            <div className="schedule-event-grid">
                                                                <div>
                                                                    <button id={"schedule-dnd-" + item.ScheduleID} className="button draggable">Drag and drop</button>
                                                                </div>
                                                                <div>
                                                                    {!item.IsCancelled &&
                                                                        <input
                                                                            id={'schedule-checkbox-' + itemIndex}
                                                                            className="checkbox schedule-checkbox"
                                                                            type="checkbox"
                                                                            checked={calendarList.find(x => x.ScheduleID === item.ScheduleID).checked}
                                                                            onChange={() => toggleRow(item.ScheduleID)}
                                                                        />
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {item.IsCancelled && <div><b className="fail">CANCELLED</b></div>}
                                                                    {item.OwnerName && <div><span className="schedule-title">{item.OwnerName}</span></div>}
                                                                    <span>
                                                                        {item.RoomDescription && <React.Fragment>{item.RoomDescription + ' ' + (item.RoomNumber || '')}</React.Fragment>}
                                                                        {item.RoomDescription && item.OwnerID !== undefined && <span className='mobile-newline'>&nbsp;|&nbsp;</span>}
                                                                        {(item.ScheduleType === "Committee" || item.ScheduleType === "Docket") && item.OwnerID !== undefined &&
                                                                            <Link to={`/session-details/${session}/committee-information/${item.CommitteeNumber}/committee-details`} target="_blank">{fullCommitteeList.find(x => x.CommitteeID === item.OwnerID) && fullCommitteeList.find(x => x.CommitteeID === item.OwnerID).ParentCommitteeID !== null ? "Subcommittee Info" : "Committee Info"}</Link>
                                                                        }
                                                                        {item.LinkURL !== undefined && item.LinkURL !== "" &&
                                                                            <React.Fragment>
                                                                                {(item.ScheduleType === "Committee" || item.ScheduleType === "Docket") && item.OwnerID !== undefined
                                                                                    ? <React.Fragment> | <Link to={item.LinkURL.substr(item.LinkURL.indexOf(".gov") + 4, item.LinkURL.length + 1)} target="_blank">Docket Info</Link></React.Fragment>
                                                                                    : <Link to={item.LinkURL.substr(item.LinkURL.indexOf(".gov") + 4, item.LinkURL.length + 1)} target="_blank">Docket Info</Link>
                                                                                }
                                                                            </React.Fragment>
                                                                        }
                                                                    </span>
                                                                    {item.Description && <div style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}><span>{renderHTML(item.Description)}</span></div>}
                                                                </div>
                                                                <div className="schedule-management-controls">
                                                                    <a className="icon edit" onClick={() => openMeeting(item)} />
                                                                    <React.Fragment>{item.IsCancelled ? <a className="icon blue-checkmark" onClick={() => toggleCancelMeeting(item)} /> : <a className="icon blocked" onClick={() => toggleCancelMeeting(item)} />} </React.Fragment>
                                                                    <a className="icon bin" onClick={() => this.togglePopup(item)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Draggable>
                                        )
                                    )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </React.Fragment>
        );
    }
}

class Schedule extends React.Component {

    render() {
        const { session, currentDay, toggleRow, calendarList, fullCommitteeList } = this.props;
        return (
            <div className="meeting-day">
                {currentDay.Times.map((time, timeIndex) =>
                    time.Items.find(x => x.IsPublic === true) && /* If there is any public meeting in the time display it, otherwise don't show the time. */
                    <React.Fragment key={timeIndex}>
                        {time.Items.map((item, itemIndex) =>
                            item.IsPublic &&
                            <div className="inner-grid no-grid-column-gap two-col-sidebar-backwards-alt-2 schedule-event">
                                <div className="public-time-wrapper">
                                    <span className="txt-greyed">{time.Time}</span>
                                </div>
                                <div>
                                    <div className="inner-grid no-grid-column-gap two-col-sidebar-tiny-backwards schedule-checkbox-and-content">
                                        <div className="public-schedule-event-checkbox-wrapper">
                                            {!item.IsCancelled &&
                                                <>
                                                    <label htmlFor={'schedule-checkbox-' + itemIndex} className="screen-reader-only">Select event</label>
                                                    <input
                                                        id={'schedule-checkbox-' + itemIndex}
                                                        className="checkbox schedule-checkbox"
                                                        type="checkbox"
                                                        checked={calendarList.find(x => x.ScheduleID === item.ScheduleID).checked}
                                                        onChange={() => toggleRow(item.ScheduleID)}
                                                    />
                                                </>
                                            }
                                        </div>
                                        <div key={itemIndex} className="public-schedule-event">
                                            {item.IsCancelled && <div><b className="fail">CANCELLED</b></div>}
                                            <div>
                                                {item.OwnerName && <div><span className="schedule-title">{item.OwnerName}</span></div>}
                                                <span>
                                                    {item.RoomDescription && <React.Fragment>{item.RoomDescription + ' ' + (item.RoomNumber || '')}</React.Fragment>}
                                                    {item.RoomDescription && item.OwnerID !== undefined && <span className='mobile-newline'>&nbsp;|&nbsp;</span>}
                                                    {(item.ScheduleType === "Committee" || item.ScheduleType === "Docket") && item.OwnerID !== undefined &&
                                                        <Link to={`/session-details/${session}/committee-information/${item.CommitteeNumber}/committee-details`} target="_blank">{fullCommitteeList.find(x => x.CommitteeID === item.OwnerID) && fullCommitteeList.find(x => x.CommitteeID === item.OwnerID).ParentCommitteeID !== null ? "Subcommittee Info" : "Committee Info"}</Link>
                                                    }
                                                    {item.LinkURL !== undefined && item.LinkURL !== "" &&
                                                        <React.Fragment>
                                                            {(item.ScheduleType === "Committee" || item.ScheduleType === "Docket") && item.OwnerID !== undefined
                                                                ? <React.Fragment> | <Link to={item.LinkURL.substr(item.LinkURL.indexOf(".gov") + 4, item.LinkURL.length + 1)} target="_blank">Docket Info</Link></React.Fragment>
                                                                : <Link to={item.LinkURL.substr(item.LinkURL.indexOf(".gov") + 4, item.LinkURL.length + 1)} target="_blank">Docket Info</Link>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </span>
                                            </div>
                                            {item.Description && <div style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}><span>{renderHTML(item.Description)}</span></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )
                }
            </div>


        );
    }
}

class ScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInstructions: false
        }

        this.close = this.close.bind(this);
        this.toggleInstructions = this.toggleInstructions.bind(this)
    }

    close() {
        this.props.closeScheduleModal();
    }

    toggleInstructions() {
        this.setState({
            showInstructions: !this.state.showInstructions
        })
    }

    render() {
        return (
            <div className="schedule-modal">
                <div id="calendar-instructions" className="schedule-modal-content">
                    <p>Add a new calendar in your calendar program that automatically reflects additions and updates to the website calendar.</p>
                    <a href="webcal://liscdn.blob.core.windows.net/cdn/meetings.ics">webcal://liscdn.blob.core.windows.net/cdn/meetings.ics</a>
                    <br />
                    <br />
                    {this.state.showInstructions
                        ? <button type="button" className="button-link" onClick={() => this.toggleInstructions()}>Hide</button>
                        : <button type="button" className="button-link" onClick={() => this.toggleInstructions()}>Show Instructions</button>
                    }
                    {this.state.showInstructions &&
                        <div className="calendar-instructions">
                            <p>In most cases, just selecting the webcal link will add a new calendar in your calendar application and subscribe to updates.</p>
                            <p>To subscribe in Google Calendar or Mozilla Thunderbird - Lightning Calendar, copy the link and paste it into the appropriate address or location field in the calendar application.</p>

                            <p>For specific instructions, see:</p>
                            <ul>
                                <li>For specific Outlook instructions, see Office Support: <a href="https://support.office.com/en-sg/article/View-and-subscribe-to-Internet-Calendars-7cd5d35a-4a90-4d70-a2f7-f6fadd7e2d9an">View and subscribe to Internet calendars.</a></li>
                                <li>Google Calendar Help: <a href="https://support.google.com/calendar/answer/37100?hl=en">Subscribe to public calendars using the calendar address.</a></li>
                                <li>Mozilla Support: <a href="https://support.mozilla.org/en-US/kb/adding-a-holiday-calendar">Adding a holiday [or other] calendar.</a></li>
                                <li>On an Android device, you can't subscribe by clicking the webcal link. Subscribe in Google Calendar, and then sync your calendars using a calendar app on your device.</li>
                            </ul>

                            <p>To unsubscribe, in your calendar program, delete the calendar.</p>
                            <p>If you use more than one calendar program (for example, Google Calendar and Outlook), you can control which program a browser opens when you subscribe via an iCalendar URL.</p>
                            <p>In your browser, follow instructions for how to set which programs handle specific file types and protocols.</p>
                            <p>For the .ics file and/or webcal protocol handler, set the calendar program you prefer.</p>

                            <p>Tip: In Lightning, you may have to replace webcal: with http: in the subscription link.</p>
                            <p>Tip: You can set different .ics and webcal handlers in each browser you use.</p>
                            <p>Tip: On the iPhone or iPad, to unsubscribe go to your device Settings. In Mail, Contacts, and Calendars, under Subscribed Calendars, delete the calendar account.</p>
                        </div>
                    }
                    <div>
                        <br />
                        <button className="button secondary" type="button" onClick={() => this.close()}>Close</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        const { committee, nav, schedule, session, login } = state;
        return {
            committee,
            nav,
            schedule,
            session,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, committeeActionCreators, sessionActionCreators, scheduleActionCreators), dispatch)
        }
    }
)(PublicSchedule)