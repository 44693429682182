import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router';
import queryString from 'query-string';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import Layout from './lis-shared/lis-layout/components/lis-layout-component';
import Login from './lis-shared/lis-login/components/lis-login-form';
import CreateAccount from './lis-shared/lis-login/components/lis-create-account-form';
import RegisterAccount from './lis-shared/lis-login/components/lis-register-account-form';
import ForgotPassword from './lis-shared/lis-login/components/lis-forgot-password-form';
import ChangePassword from './lis-shared/lis-login/components/lis-change-password-form';

import MemberManagement from './lis-admin/lis-member-management/components/lis-member-management';
import SessionManagement from './lis-admin/lis-session-management/components/lis-session-management';
import CommitteeManagement from './lis-admin/lis-committee-management/components/lis-committee-management';
import DocketManagement from './lis-admin/lis-docket-management/components/lis-docket-management';
import CalendarManagement from './lis-admin/lis-calendar-management/components/lis-calendar-management';
import MinutesManagement from './lis-admin/lis-minutes-management/components/lis-minutes-management';
import VersionManagement from './lis-admin/lis-version-management/components/lis-version-management';
import BillManagement from './lis-admin/lis-bill-management/components/lis-bill-management';
import CommunicationsManagement from './lis-admin/lis-communications-management/components/lis-communications-management';
import VoteManagement from './lis-admin/lis-vote-management/components/lis-vote-management';
import PrintManagement from './lis-admin/lis-print-management/components/lis-print-management';
import ReportManagement from './lis-admin/lis-report-management/components/lis-report-management';
import AmendmentManagement from './lis-admin/lis-amendment-management/components/lis-amendment-management';
import AdminDashboard from './lis-admin/lis-admin-dashboard/components/lis-admin-dashboard';
import NotificationManagement from './lis-admin/lis-notifications-management/components/lis-notification-management';
import PartnerManagement from './lis-admin/lis-partner-management/components/lis-partner-management';
import UserManagement from './lis-admin/lis-user-management/components/lis-user-management';

import Home from './lis-public/components/lis-home/lis-public-home';
import PublicSessionInformation from './lis-public/components/lis-public-session-list';
import PublicCommitteeInformation from './lis-public/components/lis-committee/lis-public-committee-information';
import PublicMemberInformation from './lis-public/components/lis-public-members-list';
import PublicMemberDetails from './lis-public/components/lis-public-member-details';
import PublicBillSearch from './lis-public/components/lis-bill-search/lis-public-bill-search';
import SearchReportPage from './lis-public/components/lis-bill-search/lis-report-bill-search';
import SearchTranslator from './lis-public/components/lis-bill-search/search-translator';
import ShorthandUrlTranslator from './lis-public/components/lis-bill-search/shorthand-url-translator';
import SearchHelp from './lis-public/components/lis-bill-search/lis-search-help';
import PublicBillDetails from './lis-public/components/lis-bill-details/lis-public-bill-details';
import PublicVoteDetails from './lis-public/components/lis-public-vote-details';
import PublicDataFiles from './lis-public/components/lis-public-data-files';
import PublicCommunicationsList from './lis-public/components/lis-public-communications';
import PublicCalendarList from './lis-public/components/lis-public-calendar-list';
import PublicCalendarDetails from './lis-public/components/lis-public-calendar-details';
import PublicMinutesList from './lis-public/components/lis-public-minutes';
import PublicMinutesDetails from './lis-public/components/lis-public-minutes-details';
import FullText from './lis-public/components/lis-public-full-text';
import PublicSchedule from './lis-public/components/lis-public-schedule';
import PublicVoteSearch from './lis-public/components/lis-public-vote-search';
import PublicMemberLegislation from './lis-public/components/lis-public-member-legislation';
import PublicCommunicationDetails from './lis-public/components/lis-public-communication-details';
import CommitteeStatDetails from './lis-public/components/lis-committee-statistics';
import SessionStatDetails from './lis-public/components/lis-session-statistics';
import Unauthorized from './lis-public/components/lis-unauthorized';
import ProfileForm from './lis-shared/lis-profile/components/lis-profile-form';
import PrivacyComponent from './lis-public/components/lis-privacy';
import WatchlistsComponent from './lis-public/components/lis-watchlists';

import DevsPortal from './lis-developers/public/components/lis-developers-portal';
import DocumentComponent from './lis-developers/public/components/lis-document';
import KeyRegistration from './lis-developers/public/components/lis-api-key-registration';
import KeyConfirmation from './lis-developers/public/components/lis-api-key-confirmation';
import TermsOfService from './lis-developers/public/components/lis-terms-of-service';

import AdminDocs from './lis-developers/admin/components/lis-admin-docs';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from './stores/lis-auth-store';
import { navActionCreators } from './stores/lis-nav-store';
import IdleTimer from 'react-idle-timer'
import { parse } from 'query-string';
import jwtDecode from 'jwt-decode';

import Footer from './lis-public/components/lis-footer';
import ConfigurationsManagement from './lis-admin/lis-configurations/lis-configurations-management';
import JournalManagement from './lis-admin/lis-journal-management/components/lis-journal-management';
import moment from 'moment';

const idToken = 'LIS_ID_TOKEN';
const refreshToken = 'LIS_REFRESH_TOKEN';
const refreshTokenExpiration = 'LIS_REFRESH_TOKEN_EXPIRATION';
const graphToken = 'GRAPH_ID_TOKEN';
const claimsObject = 'LIS_CLAIMS';

// Private route component for auth-only paths. If authstatus == true, show the component.
// Will eventually need to add on to it for Admin-only paths
const PrivateRoute = ({ authstatus, hasPermission, checkingToken, component: Component, ...rest }) => {
    const redirect = '?redirect=' + window.location.pathname + encodeURIComponent(window.location.search);
    return (<Route {...rest} render={(props) => (
        authstatus && hasPermission
            ? <Component {...props} />
            : checkingToken ? <div className="center-spinner spinner" />
                : !authstatus ? <Redirect to={'/login' + redirect} />
                    : <Redirect to="/unauthorized" />
    )} />)
}

class LISApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LIS_SPLASH_DISMISSED: !window.env?.LIS_SPLASH_PAGE,
            isIdle: false,
            popupMessage: '',
            counter: null,
            resources: 0,
            permissions: null,
            houseToken: "",
            checkingToken: true
        };
        this.idleTimer = null
        this.onActive = this.onActive.bind(this)
        this.onIdle = this.onIdle.bind(this)
        this.checkPermissions = this.checkPermissions.bind(this);
        this.setHouseToken = this.setHouseToken.bind(this);
    };

    checkPermissions() {
        //determine permissions for specific pages in case of direct url navigation from a logged in user who does not have specific permissions
        let permissions = [];
        this.setState({ resources: this.props.login.userClaims.resources.length })
        for (let i = 0; i < this.props.login.userClaims.resources.length; i++) {
            switch (this.props.login.userClaims.resources[i]) {
                case "All":
                    permissions.push(
                        { canAccessMemberMgmt: true },
                        { canAccessSessionMgmt: true },
                        { canAccessCommitteeMgmt: true },
                        { canAccessMinutesMgmt: true },
                        { canAccessDocketMgmt: true },
                        { canAccessBillMgmt: true },
                        { canAccessVersionMgmt: true },
                        { canAccessAmendmentMgmt: true },
                        { canAccessCommMgmt: true },
                        { canAccessCalendarMgmt: true },
                        { canAccessConfigurationsMgmt: true },
                        { canAccessJournalMgmt: true },
                        { canAccessVoteMgmt: true },
                        { canAccessPrintMgmt: true },
                        { canAccessReportMgmt: true },
                        { canAccessMeetingsMgmt: true },
                        { canAccessWatchlists: true },
                        { canAccessPartnerMgmt: true },
                        { canAccessUserMgmt: true },
                        { canAccessDocs: true },
                        { canAccessBds: true },
                        { canAccessSenateLinks: true },
                        { canAccessPatronStatusReport: true }
                    );
                    this.setState({ permissions })
                    return; //If the user is admin allow access to all links and exit
                case "Session":
                    permissions.push({ canAccessSessionMgmt: true })
                    break;
                case "Committee":
                    permissions.push({ canAccessCommitteeMgmt: true })
                    break;
                case "Subcommittee":
                    permissions.push({ canAccessCommitteeMgmt: true })
                    break;
                case "MinutesBook":
                    permissions.push({ canAccessMinutesMgmt: true })
                    break;
                case "Docket":
                    permissions.push({ canAccessDocketMgmt: true })
                    break;
                case "Agenda":
                    permissions.push({ canAccessDocketMgmt: true })
                    break;
                case "LegislationEvent":
                    permissions.push({ canAccessBillMgmt: true })
                    break;
                case "LegislationText":
                    permissions.push({ canAccessVersionMgmt: true, canAccessAmendmentMgmt: true })
                    break;
                case "LegislationCommunications":
                    permissions.push({ canAccessCommMgmt: true })
                    break;
                case "Calendar":
                    permissions.push({ canAccessCalendarMgmt: true })
                    break;
                case "Vote":
                    permissions.push({ canAccessVoteMgmt: true })
                    break;
                case "Schedule":
                    permissions.push({ canAccessMeetingsMgmt: true })
                    break;
                case "PrintDocuments":
                    permissions.push({ canAccessPrintMgmt: true })
                    break;
                case "JournalFileGeneration":
                    permissions.push({ canAccessJournalMgmt: true })
                    break;
                //Roles unaccounted for in navbar list items                
                case "AdvancedLegislationSearch":
                    break;
                case "CalendarFileGeneration":
                    break;
                case "CommunicationFileGeneration":
                    break;
                case "CommitteeLegislationReferral":
                    break;
                case "CommitteesByMemberSearch":
                    break;
                case "Communication":
                    break;
                case "Contact":
                    break;
                case "Groups":
                    break;
                case "GroupsRoles":
                    break;
                case "LegislationByCommitteeSearch":
                    break;
                case "LegislationByMember":
                    break;
                case "LegislationFileGeneration":
                    break;
                case "LegislationPatron":
                    break;
                case "LegislationSummary":
                    break;
                case "LegislationVersion":
                    break;
                case "Member":
                    permissions.push({ canAccessMemberMgmt: true })
                    break;
                case "MembersByCommittee":
                    break;
                case "MembersSearch":
                    break;
                case "MinuteEntries":
                    break;
                case "Organization":
                    break;
                case "PatronTypeByRole":
                    break;
                case "PartnerAuthentication":
                    break;
                case "Person":
                    permissions.push({ canAccessUserMgmt: true })
                    break;
                case "Personnel":
                    permissions.push({ canAccessUserMgmt: true })
                    break;
                case "Roles":
                    break;
                case "User":
                    break;
                case "VoteFileGeneration":
                    break;
                case "LegislationCollections":
                    if (this.props.login.userClaims.claims.find(claim => claim.Scope === "Paid" && claim.Resource === "LegislationCollections" && claim.RoleName === "PaidLegislationCollectionsAuthor")) permissions.push({ canAccessWatchlists: true })
                    break;
                case "ReportFileGeneration":
                    permissions.push({ canAccessReportMgmt: true })
                    break;
                case "APIDocumentation":
                    permissions.push({ canAccessDocs: true })
                    break;
                case "BDSDrafter":
                    permissions.push({ canAccessBds: true })
                    break;
                case "SenateLinks":
                    permissions.push({ canAccessSenateLinks: true })
                    break;
                default:
                    console.log("Resource unaccounted for: ", this.props.login.userClaims.resources[i]);
                    break;
            }
        }

        //If a signed in member, then provide a link to the member status report page.
        const jwt = localStorage.getItem(idToken);
        if (jwt) {
            try {
                const parsedToken = jwtDecode(jwt);
                const mNumber = parsedToken.MemberNumber;
                if (mNumber) {
                    permissions.push({ canAccessPatronStatusReport: true })
                }
            } catch (e) {
                if (Object.getPrototypeOf(e).toString() === 'InvalidTokenError') {
                    //Token is malformed..nothing to do
                } else {
                    console.error(e);
                }
            }
        }

        this.setState({ permissions })
    }

    splashPageListener = (evt) => {
        if (evt.ctrlKey && evt.key === "l") {
            this.setState({ LIS_SPLASH_DISMISSED: true }, () => {
                document.querySelector('body').removeEventListener('keydown', this.splashPageListener);
            });
        }
    }

    componentDidMount() {
        if (window.env?.LIS_SPLASH_PAGE && moment().isBefore(moment("09/23/2024"))) {
            document.querySelector('body').addEventListener('keydown', this.splashPageListener);
        }
        let tokenExp = localStorage.getItem(refreshTokenExpiration);
        if (tokenExp !== null && tokenExp !== 'undefined') {
            if (tokenExp < new Date().getTime()) {
                localStorage.removeItem(idToken);
                localStorage.removeItem(refreshToken);
                localStorage.removeItem(refreshTokenExpiration);
                localStorage.removeItem(graphToken);
                localStorage.removeItem(claimsObject);
                this.setState({ checkingToken: false })
            } else {
                if (this.props.login.isTokenExpired) {
                    if (localStorage.getItem(idToken)) {
                        this.props.actions.refreshAccessToken().finally(() => {
                            this.setState({ checkingToken: false })
                        })
                    } else {
                        this.setState({ checkingToken: false })
                    }
                } else {
                    this.setState({ checkingToken: false })
                }
            }
        } else {
            this.setState({ checkingToken: false })
        }
        this.checkPermissions();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.login.initiateRedirect !== prevProps.login.initiateRedirect) && this.props.login.initiateRedirect === true) {
            const parsed = parse(window.location.search);
            // If there is a redirect then send the user there, if not then just refresh the current page, which is easier then trying to figure out what endpoint need to be re-run to get partner information
            if (parsed.redirect) {
                this.props.history.push(parsed.redirect);
            } else if (window.location.pathname === "/login") {
                this.props.history.push("/");
            } else if (!window.location.pathname === "/change-password") { // we are manually logging in users after they successfully change their password, prevent an undesired refresh as a result of changing props
                window.location.reload();
            }
        }

        //If user permissions have changed
        if (this.props.login.userClaims.resources.length !== this.state.resources) {
            this.checkPermissions();
        }
    }

    onActive(e) {
        console.log('user is active', e)
        console.log('time elapsed', this.idleTimer.getElapsedTime())
        this.setState({
            isIdle: false
        });
    }

    onIdle(e) {
        console.log('user is idle', e)
        console.log('last active', this.idleTimer.getLastActiveTime())
        if (this.props.login.isAuthenticated) {
            this.setState({
                isIdle: true,
                popupMessage: 'It looks like you have been away and you are about to time out. Need more time?'
            });
        }
    }

    setHouseToken(houseToken, expirationDate) {
        this.setState({
            houseToken
        }, () => {
            //Find the refresh token in the mess MSAL drops into local storage because they won't give me a function to get it properly -JGB
            let keys = Object.keys(localStorage);
            let refreshToken = "";
            keys.forEach((k, i) => {
                let value = localStorage.getItem(k);
                if (typeof value === "object") {
                    let currentItem = JSON.parse(value);
                    if (currentItem["credentialType"] === "RefreshToken") {
                        refreshToken = currentItem["secret"];
                    }
                }
            })
            this.props.actions.houseLogin(this.state.houseToken, refreshToken, expirationDate);
        })
    }

    render() {
        const { isAuthenticated } = this.props.login;
        const { permissions, checkingToken } = this.state;

        // IHOD wants to display the bill details page in an iframe without the site header
        if (window.location.pathname.includes("static/bill-details")) {
            return (
                <div>
                    <Route path="/static/bill-details/:sessionCode/:billnumber" component={PublicBillDetails} />
                </div>
            )
        }

        return (
            permissions &&
            <IdleTimer
                ref={ref => { this.idleTimer = ref }}
                element={document}
                onActive={this.onActive}
                onIdle={this.onIdle}
                //2 hours
                timeout={60000 * 120}
            >
                {window.env?.LIS_SPLASH_PAGE && moment().isBefore(moment("09/23/2024")) && !this.state.LIS_SPLASH_DISMISSED &&
                    <div className="splash-page">
                        <div className="splash-page-content">
                            <div className="under-construction icon settings" />
                            <h1>Currently under construction, thank you for your patience.<br />The new LIS site will be ready for you on <b>September 23rd, 2024</b>.</h1>
                        </div>
                    </div>
                }
                <div ref={(divElement) => { this.divElement = divElement; }} className={this.divElement && this.divElement.offsetHeight > window.innerHeight ? "overheight" : ""} id="height-checker">
                    <Layout isIdle={this.state.isIdle} popupMessage={this.state.popupMessage} location={this.props.location} permissions={permissions} >
                        <Route exact path='/' component={Home} />
                        <Route exact path='/home' component={Home} />
                        <Route exact path='/home/:sessionCode' component={Home} />
                        <Route exact path="/bill-search" component={PublicBillSearch} />
                        <Route exact path="/bill-search-report/:id" component={SearchReportPage} />
                        <Route exact path={["/bill-search/:id/:word", "/bill-search/:id/:word/:wordparam"]} component={SearchTranslator} />
                        <Route exact path="/q" component={ShorthandUrlTranslator} />
                        <Route exact path="/q/:params" component={ShorthandUrlTranslator} />
                        <Route exact path='/search-help' component={SearchHelp} />
                        <Route exact path='/bill-details/:sessionCode/:billnumber' component={!this.props.nav.showOverlay && PublicBillDetails} />
                        <Route exact path='/bill-details/:sessionCode/:billnumber/text/:documentcode' component={FullText} />
                        <Route exact path='/bill-details/:sessionCode/:billnumber/text/:documentcode/hilite' component={FullText} />
                        <Route exact path='/vote-details/:billnumber/:sessioncode/:votenumber' component={PublicVoteDetails} />
                        <Route exact path='/data-files' component={PublicDataFiles} />
                        <Route exact path='/data-files/:sessionCode' component={PublicDataFiles} />
                        <Route exact path='/schedule' component={PublicSchedule} />
                        <Route exact path='/session-information' component={PublicSessionInformation} />
                        <Route exact path='/session-details/:sessioncode/communications' component={PublicCommunicationsList} />
                        <Route exact path='/session-details/:sessioncode/communications/:commid' component={PublicCommunicationDetails} />
                        <Route exact path='/session-details/:sessioncode/calendar' component={PublicCalendarList} />
                        <Route exact path='/session-details/:sessioncode/calendar/:calendarid' component={PublicCalendarDetails} />
                        <Route exact path='/session-details/:sessioncode/minutes-list/:chamber' component={PublicMinutesList} />
                        <Route exact path='/session-details/:sessioncode/minutes/:minutesid' component={PublicMinutesDetails} />
                        <Route path='/session-details/:sessioncode/committee-information' component={PublicCommitteeInformation} />
                        <Route path={['/vote-search/:sessioncode/:membernumber', '/vote-search/:sessioncode']} component={PublicVoteSearch} />
                        <Route exact path='/session-details/:sessioncode/member-information' component={PublicMemberInformation} />
                        <Route exact path='/session-details/:sessioncode/member-information/:membernumber/member-details' component={PublicMemberDetails} />
                        <Route exact path='/session-details/:sessioncode/member-legislation/:chambercode' component={PublicMemberLegislation} />
                        <Route exact path='/session-details/:sessioncode/statistics/committee/:chambercode' component={CommitteeStatDetails} />
                        <Route exact path='/session-details/:sessioncode/statistics/status/' component={SessionStatDetails} />
                        <Route exact path='/watchlists' component={WatchlistsComponent} />

                        <Route exact path='/register-account' component={RegisterAccount} />
                        <Route exact path='/registration/:registerhash' component={CreateAccount} />
                        <Route exact path='/forgot-password' component={ForgotPassword} />
                        <Route exact path={'/passwordreset/:resethash'} component={ChangePassword} />

                        <Route exact path='/login' component={Login} authstatus={isAuthenticated} />

                        <Route exact path='/developers' component={DevsPortal} />
                        <Route exact path='/developers/:docName' component={DocumentComponent} />
                        <Route exact path='/apiregistration' component={KeyRegistration} />
                        <Route exact path='/apiregistration/:params' component={KeyConfirmation} />
                        <Route exact path='/tos' component={TermsOfService} />

                        <Route exact path='/unauthorized' component={Unauthorized} />

                        <Route exact path='/privacy' component={PrivacyComponent} />

                        <PrivateRoute path='/member-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessMemberMgmt))} component={MemberManagement} />
                        <PrivateRoute path='/session-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessSessionMgmt))} component={SessionManagement} />
                        <PrivateRoute path='/committee-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessCommitteeMgmt))} component={CommitteeManagement} />
                        <PrivateRoute path='/docket-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessDocketMgmt))} component={DocketManagement} />
                        <PrivateRoute path='/calendar-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessCalendarMgmt))} component={CalendarManagement} />
                        <PrivateRoute path='/configurations-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessConfigurationsMgmt))} component={ConfigurationsManagement} />
                        <PrivateRoute path='/minutes-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessMinutesMgmt))} component={MinutesManagement} />
                        <PrivateRoute path='/communications-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessCommMgmt))} component={CommunicationsManagement} />
                        <PrivateRoute path='/version-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessVersionMgmt))} component={VersionManagement} />
                        <PrivateRoute path='/bill-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessBillMgmt))} component={BillManagement} />
                        <PrivateRoute path='/vote-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessVoteMgmt))} component={VoteManagement} />
                        <PrivateRoute path='/print-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessPrintMgmt))} component={PrintManagement} />
                        <PrivateRoute path='/report-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessReportMgmt))} component={ReportManagement} />
                        <PrivateRoute path='/amendment-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessAmendmentMgmt))} component={AmendmentManagement} />
                        <PrivateRoute path='/partner-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessPartnerMgmt))} component={PartnerManagement} />
                        <PrivateRoute path='/user-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessMemberMgmt))} component={UserManagement} />
                        <PrivateRoute path='/journal-management' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessJournalMgmt))} component={JournalManagement} />
                        <PrivateRoute path='/dashboard' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={true} component={AdminDashboard} />
                        <PrivateRoute path='/notifications' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={true} component={NotificationManagement} />
                        <PrivateRoute path='/profile' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={true} component={ProfileForm} />
                        <PrivateRoute path='/change-password' checkingToken={checkingToken} authstatus={isAuthenticated || (this.props.location.search && queryString.parse(this.props.location.search).emailaddress)} hasPermission={true} component={ChangePassword} />
                        <PrivateRoute path='/admin-developers' checkingToken={checkingToken} authstatus={isAuthenticated} hasPermission={Boolean(permissions.find(p => p.canAccessDocs))} component={AdminDocs} />
                    </Layout>
                </div>

                {!this.props.location.pathname.includes("management") &&
                    <Footer />
                }

                <AuthenticatedTemplate>
                    <ProfileContent
                        setHouseToken={this.setHouseToken}
                        houseToken={this.state.houseToken}
                        isAuthenticated={this.props.login.isAuthenticated}
                    />
                </AuthenticatedTemplate>

            </IdleTimer>
        );
    }
}

function ProfileContent(props) {
    const { instance, accounts } = useMsal();

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            props.setHouseToken(response.accessToken, response.expiresOn);
            return true;
        }).catch((e) => {
            console.log(e)
        });
    }

    if (props.houseToken === "" && !props.isAuthenticated) {
        RequestAccessToken();
    }

    return (
        null
    );
};

// Connect is wrapped by withRouter. 
// This is the only way history.push and Redirect will work when also using Connect. 
const App = withRouter(connect(
    (state) => {
        const { login, nav } = state;
        return {
            login,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators), dispatch)
        }
    }
)(LISApp))

export default App;