import React from 'react';
import { Link } from 'react-router-dom';
import * as Fields from '../../lis-layout/components/lis-forms-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { navActionCreators } from '../../../stores/lis-nav-store';
import HouseLoginButton from './lis-house-login-button';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isSubmitting: false,
            errorMessage: '',
            houseLogin: false
        };

        this.checkForHouseDomain = this.checkForHouseDomain.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    checkForHouseDomain() {
        let userNameSplit = this.state.username.split("@");

        this.setState({
            houseLogin: userNameSplit[1] && userNameSplit[1] === "house.virginia.gov" ? true : false
        })
    }

    handleChange(key, val) {
        this.setState({
            [key]: val
        }, () => {
            if (key === "username") {
                this.checkForHouseDomain();
            }
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.houseLogin) { return; }
        this.setState({
            isSubmitting: true
        })
        const params = {
            userId: this.state.username,
            userPassword: this.state.password
        }
        this.props.actions.loginUser(params)
            .catch(err => {
                if (err.IsPasswordTemporaryOrExpired) {
                    this.props.history.push('/change-password?emailaddress=' + params.userId + '&passwordistemporaryorexpired=true');
                } else {
                    const failureMessage = err.FailureMessage;
                    const failureCode = failureMessage?.match(/Login failed \(\d+\)/);
                    this.setState({
                        errorMessage: failureCode ? <span className="message-error">Login failed. Invalid Email or Password. Please try again or <a style={{ display: "inline-block", padding: "0px", margin: "0px" }} href={`mailto:LIS@dlas.virginia.gov?subject=Login failed - invalid User ID or Password -- ${failureCode[0].match(/\(\d+\)/)}`}>contact us</a> for assistance if the issue persists.</span> : <span className="message-error">{failureMessage ?? JSON.stringify(err)}</span>,
                        isSubmitting: false
                    });
                }
            })
    }

    render() {
        const {
            isSubmitting,
            errorMessage,
            username,
            password,
            houseLogin
        } = this.state;

        const {
            isSubmenu
        } = this.props;

        return (
            <div className={!isSubmenu && "inner-grid two multi-row"}>
                <div className="dlas-forms">
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <div className="single-row">
                                {isSubmenu
                                    ? <span className="bold txt-blue">Log in to Lobbyist-in-a-Box Account</span>
                                    : <h3>Log in to Lobbyist-in-a-Box Account</h3>}
                            </div>
                            <div className="single-row">
                                <Fields.TextInput
                                    id="userId"
                                    autoFocus={true}
                                    type="text"
                                    placeholder="Email"
                                    value={username}
                                    onChange={e => this.handleChange('username', e.target.value)}
                                />
                            </div>
                            <div className="single-row">
                                {!houseLogin
                                    ? <Fields.TextInput
                                        id="userPassword"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={e => this.handleChange('password', e.target.value)}
                                        onFocus={() => this.checkForHouseDomain()}
                                    />
                                    : <HouseLoginButton username={username} />
                                }
                            </div>
                            <div className="single-row" style={!isSubmenu ? { marginTop: "10px" } : {}}>
                                {!houseLogin && <button className="button secondary" type="submit" disabled={!username || !password || isSubmitting}>Login</button>}
                            </div>
                            {!houseLogin && errorMessage}
                        </div>
                    </form>
                    {isSubmenu && <hr className="faded-line full-width" />}
                    <div className="flex-row" style={!isSubmenu ? { marginTop: "10px" } : {}}>
                        <Link style={{ textDecoration: "none" }} to="/forgot-password">Forgot Password</Link>
                        <Link style={{ textDecoration: "none" }} to="/register-account">Register Account</Link>
                    </div>
                </div>
                {!isSubmenu &&
                    <div className="txt-blue">
                        <h3>Track up to 5 bills for free</h3>
                        <p>Your new free account will allow you to track up to 5 bills in one Watchlist and set up notifications.</p>
                        <p>Email notification triggers an email when bills are sponsored by a member of the General Assembly, referred to a House or Senate Committee or contain a specific key word(s), phrases, or Code section(s).</p>

                        <h3>Lobbyist-in-a-Box Subscription</h3>
                        <p>A subscription to Lobbyist-in-a-Box offers up to 100 Watchlists (previously called "Profiles") containing 300 bills each.</p>
                        <p>The cost of this service is $400 for state agencies and $600 for all other subscribers.</p>
                        <p>To subscribe, first register for a free account if you have not already done so and please take a look at the <a style={{ textDecoration: "none" }} href="https://vi.virginiainteractive.org/vi/premium-liab-subscribe.shtml">subscription fee agreement</a>.</p>
                        <p><span className="bold">Need assistance?</span>  Please call: Division of Legislative Automated Systems DLAS (804-786-9631) or Virginia Interactive (804-318-4133) for additional information or assistance.</p>
                    </div>
                }
            </div>
        );

    }
};

export default connect(
    (state) => {
        const { login, nav } = state;
        return {
            login,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, navActionCreators), dispatch)
        }
    }
)(LoginForm);





