import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import Submenu from './lis-submenu-component';
import SearchInput from './lis-global-search-input-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import { navActionCreators } from '../../../stores/lis-nav-store';


class NavMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            pathName: ''
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClosingLoginOnOffClick = this.handleClosingLoginOnOffClick.bind(this);
        this.mousedownListener = this.mousedownListener.bind(this);
    }

    componentDidMount() {
        const pathName = this.props.location.pathname;
        this.setState({ pathName: pathName })

        document.body.addEventListener('mousedown', this.mousedownListener);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(prevState => ({
                showLogin: false,
                pathName: this.props.location.pathname
            }));
        }
        if (this.props.location.pathname !== prevProps.location.pathname && this.props.isOpen) {
            this.props.slideoutToggle();
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('mousedown', this.mousedownListener)
    }

    mousedownListener(e) {
        if (this.state.showLogin) {
            const loginBox = document.getElementsByClassName("nav-submenu")[0];
            const profileLink = document.getElementsByClassName("profile-link")[0];
            const loginLink = document.getElementsByClassName("login-link")[0];
            if ((!loginBox || (loginBox !== e.target && !loginBox.contains(e.target))) &&
                (!loginLink || (loginLink !== e.target && !loginLink.contains(e.target))) &&
                (!profileLink || (profileLink !== e.target && !profileLink.contains(e.target)))) {
                this.handleClosingLoginOnOffClick();
            }
        }
    }

    handleClosingLoginOnOffClick() {
        if (this.state.showLogin) {
            this.setState({
                showLogin: false
            });
        }
    }

    handleClick() {
        this.setState(prevState => ({
            showLogin: !prevState.showLogin
        }));
    }

    slideoutToggle() {
        this.props.slideoutToggle();
    }

    render() {
        const { isAuthenticated, userProfile } = this.props.login;
        // Make dynamic button based on user's auth state
        let button;
        if (!isAuthenticated) {
            button = <LoginButton onClick={this.handleClick} />;
        } else {
            button = <ProfileButton onClick={this.handleClick} name={userProfile.initials} />
        }
        // Make dynamic header based on URL path
        let header;
        let isBillPath = this.state.pathName.includes('/bill-search');
        if (isBillPath) {
            header = <h1>Bills &amp; Resolutions </h1>
        } else {
            header = <h1>Legislative Information System </h1>
        }
        // Assemble session-aware budget url
        let budgetUrl = "https://budget.lis.virginia.gov/";
        if (this.props.session) {
            budgetUrl += `default/${this.props.session.SessionCode.substr(0, 4)}/${this.props.session.SessionCode.substr(4)}`
        }

        return (
            <nav>
                <div className="lis-links-content full">
                    <ul>
                        <li><a href="http://virginiageneralassembly.gov/">Virginia General Assembly</a> /</li>
                        <li><a href="http://help.lis.virginia.gov/" target="_blank" rel="noreferrer">LIS Help</a> /</li>
                        <li><Link to="/privacy" target="_blank" rel="noreferrer">Privacy Policy</Link> /</li>
                        <li>
                            <Link to="/home">LIS Home</Link> /
                        </li>
                        {!isAuthenticated && <li>
                            <Link to="/register-account">Register Account</Link> /
                        </li>}
                        <li onClick={e => e.stopPropagation()} className="parent-link">
                            {button}
                            <div className="submenu-container">
                                <CSSTransitionGroup
                                    transitionName="slide"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300}
                                >
                                    {this.state.showLogin && <Submenu history={this.props.history} isAuth={isAuthenticated} permissions={this.props.permissions} />}
                                </CSSTransitionGroup>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="grid-wrapper one-and-three halves">
                    <div className="menu-title">
                        <Link to="/home">LIS</Link>
                    </div>
                    <div className="menu-links">
                        <div className="menu-btn">
                            <button type="button" onClick={this.slideoutToggle.bind(this)} href="#menu" tabIndex="0" aria-label="Menu">Menu</button>
                        </div>
                        <ul>
                            <li>
                                <Link className="txt-blue" to={`/`}>Session Information</Link>
                            </li>
                            <li>
                                <Link className="txt-dark-blue" to="/bill-search">Bills & Resolutions</Link>
                            </li>
                            <li>
                                <a className="txt-green" target="_blank" href={`${budgetUrl}`}>State Budget</a>
                            </li>
                            <li>
                                <a className="txt-light-green" target="_blank" href="https://law.lis.virginia.gov/">Virginia Law</a>
                            </li>
                            <li>
                                <a className="txt-orange" target="_blank" href="https://rga.lis.virginia.gov/">Reports to the General Assembly</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="site-title-container" className={isBillPath ? 'bill-theme' : null}>
                    <div className="site-title-content grid-wrapper three-and-two">
                        {header}
                        <SearchInput />
                    </div>
                </div>
            </nav>
        )
    }
}

function LoginButton(props) {
    return (
        <a className="login-link" onClick={props.onClick}>
            Login
        </a>
    );
}

function ProfileButton(props) {
    return (
        <a className="profile-link" onClick={props.onClick}>
            {props.name}
        </a>
    );
}

const Menu = connect(
    (state) => {
        const { login, nav } = state;
        return {
            login,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators, authActionCreators), dispatch)
        }
    }
)(NavMenu)

export default withRouter(Menu);