import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

class LegislationProfiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSection: window.innerWidth <= 900 ? false : true, //900px is when mobile layout is used (set in mobile.scss); collapse by default
            name: '',
            filteredWatchlists: [],
            filteredWatchlistsValue: ''
        };
        this.toggleSection = this.toggleSection.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.createCollection = this.createCollection.bind(this);
        this.filterWatchlist = this.filterWatchlist.bind(this);
    }

    toggleSection() {
        this.setState(state => ({
            showSection: !state.showSection
        }));
    }

    handleTextChange(key, val) {
        this.setState({
            [key]: val
        });
    }

    createCollection() {
        let collection = {
            Name: this.state.name,
            Description: "Created on " + moment().format('MM/DD/YYYY HH:mm'),
            WatchListLegislation: []
        };
        this.props.createCollection(collection);
        this.setState({
            name: ''
        });
    }

    filterWatchlist(e) {
        const val = e.target.value.toLowerCase();
        this.setState({
            // Map is used to preserve the original index
            filteredWatchlists: this.props.collections.map(coll => {
                if ((coll.Description && coll.Description.toLowerCase().includes(val)) || (coll.Name && coll.Name.toLowerCase().includes(val))) {
                    return coll;
                } else {
                    return null;
                }
            }),
            filteredWatchlistsValue: val
        })
    }

    render() {
        const watchlists = this.state.filteredWatchlistsValue ? this.state.filteredWatchlists : this.props.collections;
        const selectedWatchlistIndex = this.props.collections[this.props.selectedCollectionIndex] ? watchlists.findIndex(w => w && w.WatchListID === this.props.collections[this.props.selectedCollectionIndex].WatchListID) : -1
        return (
            <div className={this.props.isBillManagement ? "dlas-forms side-form advanced-search management" : "dlas-forms side-form advanced-search"}>
                <div className="header flex-row flex-vertical-center">
                    <h3 className="center category-header">Lobbyist-in-a-Box</h3>
                    <button onClick={this.toggleSection} type="button" className={this.state.showSection ? "arrow-up white-chevron" : "arrow-down white-chevron"}></button>
                </div>
                {this.state.showSection &&
                    <div className="content my-watchlist-content">
                        {this.props.isAuthor ?
                            <div>
                                <fieldset>
                                    <div>
                                        <label htmlFor="sr-watchlist-name" className="txt-dark-blue small-text">Create New Watchlist</label>
                                        <div className="inner-grid three-and-one">
                                            {!this.props.isBillManagement &&
                                                <>
                                                    <input
                                                        aria-label="Name"
                                                        id="sr-watchlist-name"
                                                        type="text"
                                                        placeholder="Name"
                                                        value={this.state.name}
                                                        onChange={e => this.handleTextChange("name", e.target.value)}
                                                    />
                                                    <button aria-label="create watchlist" className="button" disabled={this.props.collectionIsSaving || !this.state.name} onClick={this.createCollection} type="button">Create</button>
                                                    <hr className="faded-line" />
                                                </>
                                            }
                                        </div>
                                        {watchlists.length ?
                                            <div style={{marginBottom: "10px", marginTop: "-5px"}}>
                                                <label htmlFor="sr-watchlist-find" className="screen-reader-only">Find a watchlist</label>
                                                <input
                                                    id="sr-watchlist-find"
                                                    type="text"
                                                    placeholder="Find a watchlist"
                                                    value={this.state.filteredWatchlistsValue}
                                                    onChange={this.filterWatchlist} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {this.props.error && <span className="small-text fail">{this.props.error}</span>}
                                    {this.props.isLoading ?
                                        <div className="spinner center-spinner"></div>
                                        :
                                        watchlists.length ?
                                            <div className="checkbox-container watchlist-collection-list">
                                                {watchlists.map((list, listIndex) =>
                                                    <React.Fragment key={listIndex}>
                                                        {list &&
                                                            <div role="button" onClick={() => !this.props.collectionIsSaving && this.props.selectCollection(this.props.selectedCollectionIndex === listIndex ? -1 : listIndex)} className="watchlist-collection-item">
                                                                <div className={this.props.selectedCollectionIndex === listIndex ? 'selected-collection' : ''}>
                                                                    <div className="small-text">{list.Name}</div>
                                                                    <div className="small-text txt-greyed">Last Modified {list.ModificationDate ? moment(list.ModificationDate.replace(' +', '+')).format('MM/DD/YYYY hh:mm a') : moment().format('MM/DD/YYYY hh:mm a')}</div>
                                                                </div>
                                                                {listIndex !== watchlists.length - 1 && <hr className="faded-line" />}
                                                            </div>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </div> : null
                                    }
                                    <div className="homepage-links" style={{ textAlign: "center", marginTop: watchlists.length ? '10px' : '0px' }}>
                                        <Link to="/watchlists">Your Lobbyist-in-a-Box Watchlists</Link>
                                    </div>
                                </fieldset>
                            </div>
                            :
                            <div className="watchlist-login-wrapper homepage-links">
                                <Link style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "5px", display: "table" }} to="/login?redirect=/bill-search">Log in to View Watchlists</Link>
                            </div>
                        }
                    </div>

                }
            </div>
        )
    }
}


export default LegislationProfiles;
