import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActionCreators } from '../../../stores/lis-auth-store';
import HouseLogoutButton from './lis-house-logout-button';

const Links = (props) => {
    let unsortedLinks = [];
    props.canAccessMemberMgmt &&
        unsortedLinks.push({
            route: "/member-management", label: "Member Management"
        });
    props.canAccessSessionMgmt &&
        unsortedLinks.push({
            route: "/session-management", label: "Session Management"
        });
    props.canAccessCommitteeMgmt &&
        unsortedLinks.push({
            route: "/committee-management", label: "Committee Management"
        });
    props.canAccessMinutesMgmt &&
        unsortedLinks.push({
            route: "/minutes-management", label: "Minutes Management"
        });
    props.canAccessDocketMgmt &&
        unsortedLinks.push({
            route: "/docket-management", label: "Docket Management"
        });
    props.canAccessBillMgmt &&
        unsortedLinks.push({
            route: "/bill-management", label: "Bill Management"
        });
    props.canAccessVersionMgmt &&
        unsortedLinks.push({
            route: "/version-management", label: "Version Management"
        });
    props.canAccessAmendmentMgmt &&
        unsortedLinks.push({
            route: "/amendment-management", label: "Amendment Management"
        });
    props.canAccessCommMgmt &&
        unsortedLinks.push({
            route: "/communications-management", label: "Communications Management"
        });
    props.canAccessCalendarMgmt &&
        unsortedLinks.push({
            route: "/calendar-management", label: "Calendar Management"
        });
    props.canAccessConfigurationsMgmt &&
        unsortedLinks.push({
            route: "/configurations-management", label: "Configurations Management"
        });
    props.canAccessJournalMgmt &&
        unsortedLinks.push({
            route: "/journal-management", label: "Journal Management"
        });
    props.canAccessVoteMgmt &&
        unsortedLinks.push({
            route: "/vote-management", label: "Vote Management"
        });
    props.canAccessPrintMgmt &&
        unsortedLinks.push({
            route: "/print-management", label: "Print Management"
        });
    props.canAccessMeetingsMgmt &&
        unsortedLinks.push({
            route: "/schedule", label: "Meetings Management"
        });
    props.canAccessPartnerMgmt &&
        unsortedLinks.push({
            route: "/partner-management", label: "Partner Management"
        });
    props.canAccessUserMgmt &&
        unsortedLinks.push({
            route: "/user-management", label: "User Management"
        });
    props.canAccessBds &&
        unsortedLinks.push({
            route: "http://test-bds.dlas.virginia.gov/default.aspx?srv=6&ses=" + props.selectedSession.SessionCode.substr(2, 5) + "&usr=D", label: "Bill Drafting System (BDS)", bds: true
        });

    return (
        <div>
            {unsortedLinks.sort((a, b) => a.label.localeCompare(b.label)).map((link, idx) => {
                return !link.bds ? <li key={idx}><Link to={link.route}>{link.label}</Link></li> : <li key={idx}><a href={link.route}>{link.label}</a></li>
            })
            }
            {
                props.canAccessReportMgmt &&
                <li><Link to="/report-management">Rule Reports</Link></li>
            }
            {
                props.canAccessSenateLinks &&
                <React.Fragment>
                    <li><a href="http://apps.dlas.virginia.gov/newamendments/ViewAmendmentsCommittee.aspx?hou=S" target="_blank" rel="noopener noreferrer">Senate Committee Amendments</a></li>
                    <li><a href="https://apps.dlas.virginia.gov/ComSubNew/Default.aspx" target="_blank" rel="noopener noreferrer">Senate Committee Substitutes</a></li>
                    <li><a href="https://apps.dlas.virginia.gov/newamendments/frmFloorAmendments.aspx?hou=S" target="_blank" rel="noopener noreferrer">Senate Floor Amendments</a></li>
                    <li><a href="https://apps.dlas.virginia.gov/newamendments/frmFloorSubstitutes.aspx?hou=S" target="_blank" rel="noopener noreferrer">Senate Floor Substitutes</a></li>
                    <li><a href="https://apps.dlas.virginia.gov/comsubnew/frmCommitteeSummary.aspx" target="_blank" rel="noopener noreferrer">Senate Committee Vote Summary</a></li>
                </React.Fragment>
            }
            {
                props.canAccessPatronStatusReport &&
                <li><a href="http://efile.dlas.virginia.gov/frmMemberBD.aspx" target="_blank" rel="noopener noreferrer">Patron Status Report</a></li>
            }
            {
                props.isLoggedIn &&
                <li><Link to="/watchlists">{props.canAccessWatchlists ? "My Watchlists" : "My Watchlist"}</Link></li>
            }
        </div>
    )
}

class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndexNum: '-1',
        }

        this.targetFocus = React.createRef();
    }

    handleLogout(props) {
        this.props.actions.logoutUser();
        if (this.props.login.slideout) {
            this.props.login.slideoutToggle();
        }
    }

    render() {
        const {
            isOpen,
            isAriaHidden,
            isAriaExpanded,
            isMenuVisible,
            slideout,
            permissions
        } = this.props;

        const {
            tabIndexNum
        } = this.state;

        if (isOpen) {
            this.targetFocus.current.focus();
        }

        return (
            <div>
                {!slideout
                    ?
                    <div>
                        <p className="welcome-txt">Hi, {this.props.login.userProfile.name}</p>
                        <ul>
                            <Links
                                canAccessMemberMgmt={Boolean(permissions.find(p => p.canAccessMemberMgmt))}
                                canAccessSessionMgmt={Boolean(permissions.find(p => p.canAccessSessionMgmt))}
                                canAccessCommitteeMgmt={Boolean(permissions.find(p => p.canAccessCommitteeMgmt))}
                                canAccessMinutesMgmt={Boolean(permissions.find(p => p.canAccessMinutesMgmt))}
                                canAccessDocketMgmt={Boolean(permissions.find(p => p.canAccessDocketMgmt))}
                                canAccessVersionMgmt={Boolean(permissions.find(p => p.canAccessVersionMgmt))}
                                canAccessAmendmentMgmt={Boolean(permissions.find(p => p.canAccessAmendmentMgmt))}
                                canAccessBillMgmt={Boolean(permissions.find(p => p.canAccessBillMgmt))}
                                canAccessCommMgmt={Boolean(permissions.find(p => p.canAccessCommMgmt))}
                                canAccessCalendarMgmt={Boolean(permissions.find(p => p.canAccessCalendarMgmt))}
                                canAccessConfigurationsMgmt={Boolean(permissions.find(p => p.canAccessConfigurationsMgmt))}
                                canAccessJournalMgmt={Boolean(permissions.find(p => p.canAccessJournalMgmt))}
                                canAccessVoteMgmt={Boolean(permissions.find(p => p.canAccessVoteMgmt))}
                                canAccessPrintMgmt={Boolean(permissions.find(p => p.canAccessPrintMgmt))}
                                canAccessReportMgmt={Boolean(permissions.find(p => p.canAccessReportMgmt))}
                                canAccessMeetingsMgmt={Boolean(permissions.find(p => p.canAccessMeetingsMgmt))}
                                canAccessWatchlists={Boolean(permissions.find(p => p.canAccessWatchlists))}
                                canAccessPartnerMgmt={Boolean(permissions.find(p => p.canAccessPartnerMgmt))}
                                canAccessUserMgmt={Boolean(permissions.find(p => p.canAccessUserMgmt))}
                                canAccessSenateLinks={Boolean(permissions.find(p => p.canAccessSenateLinks))}
                                canAccessPatronStatusReport={Boolean(permissions.find(p => p.canAccessPatronStatusReport))}
                                canAccessBds={Boolean(permissions.find(p => p.canAccessBds))}
                                isLoggedIn={this.props.login.isAuthenticated}
                                bdsRole={this.props.login.bdsRole}
                                selectedSession={this.props.session.selectedSession}
                            />
                        </ul>
                        <p className="profile-action-container">
                            <a href="/profile" className="button profile-action informational" style={{ margin: '0px', padding: '7px 20px' }}>Profile</a>
                            {this.props.login.userProfile.email.split("@")[1] === "house.virginia.gov"
                                ? <HouseLogoutButton
                                    logout={this.props.actions.logoutUser}
                                />
                                : <button className="button profile-action danger" onClick={this.handleLogout.bind(this)}>Logout</button>}
                        </p>
                    </div>
                    :
                    <nav id="slideout-menu" tabIndex={tabIndexNum} aria-expanded={isAriaExpanded} aria-hidden={isAriaHidden}>
                        <div className={isMenuVisible ? 'slideout-inner show' : 'slideout-inner'}>
                            <ul>
                                <li className="menu-heading">LIS</li>
                                <li><a ref={this.targetFocus} href="http://virginiageneralassembly.gov/">Virginia General Assembly</a></li>
                                <li><a href="http://help.lis.virginia.gov/" target="_blank" rel="noreferrer">LIS Help</a></li>
                                <li><Link to="/home">LIS Home</Link></li>
                                <Links
                                    canAccessMemberMgmt={Boolean(permissions.find(p => p.canAccessMemberMgmt))}
                                    canAccessSessionMgmt={Boolean(permissions.find(p => p.canAccessSessionMgmt))}
                                    canAccessCommitteeMgmt={Boolean(permissions.find(p => p.canAccessCommitteeMgmt))}
                                    canAccessMinutesMgmt={Boolean(permissions.find(p => p.canAccessMinutesMgmt))}
                                    canAccessDocketMgmt={Boolean(permissions.find(p => p.canAccessDocketMgmt))}
                                    canAccessBillMgmt={Boolean(permissions.find(p => p.canAccessBillMgmt))}
                                    canAccessVersionMgmt={Boolean(permissions.find(p => p.canAccessVersionMgmt))}
                                    canAccessAmendmentMgmt={Boolean(permissions.find(p => p.canAccessAmendmentMgmt))}
                                    canAccessCommMgmt={Boolean(permissions.find(p => p.canAccessCommMgmt))}
                                    canAccessCalendarMgmt={Boolean(permissions.find(p => p.canAccessCalendarMgmt))}
                                    canAccessConfigurationsMgmt={Boolean(permissions.find(p => p.canAccessConfigurationsMgmt))}
                                    canAccessJournalMgmt={Boolean(permissions.find(p => p.canAccessJournalMgmt))}
                                    canAccessVoteMgmt={Boolean(permissions.find(p => p.canAccessVoteMgmt))}
                                    canAccessPrintMgmt={Boolean(permissions.find(p => p.canAccessPrintMgmt))}
                                    canAccessReportMgmt={Boolean(permissions.find(p => p.canAccessReportMgmt))}
                                    canAccessMeetingsMgmt={Boolean(permissions.find(p => p.canAccessMeetingsMgmt))}
                                    canAccessWatchlists={Boolean(permissions.find(p => p.canAccessWatchlists))}
                                    canAccessPartnerMgmt={Boolean(permissions.find(p => p.canAccessPartnerMgmt))}
                                    canAccessUserMgmt={Boolean(permissions.find(p => p.canAccessUserMgmt))}
                                    canAccessSenateLinks={Boolean(permissions.find(p => p.canAccessSenateLinks))}
                                    canAccessPatronStatusReport={Boolean(permissions.find(p => p.canAccessPatronStatusReport))}
                                    canAccessBds={Boolean(permissions.find(p => p.canAccessBds))}
                                    isLoggedIn={this.props.login.isAuthenticated}
                                    selectedSession={this.props.session.selectedSession}
                                />
                                {
                                    this.props.login.isAuthenticated &&
                                    <li><Link to="/profile">Profile</Link></li>
                                }
                                {this.props.login.userProfile.name === ""
                                    ?
                                    <React.Fragment>
                                        <li><Link to="/register-account">Register Account</Link></li>
                                        <li><Link to="/login">Login</Link></li>
                                    </React.Fragment>
                                    : this.props.login.userProfile.email.split("@")[1] === "house.virginia.gov"
                                        ? <HouseLogoutButton />
                                        : <li onClick={this.handleLogout.bind(this)}><a className="slideout-nav-logout">Logout</a></li>


                                }
                            </ul>
                        </div>
                    </nav>
                }
            </div>
        )
    }
};

export default connect(
    (state) => {
        const { login, session } = state;
        return {
            session,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators), dispatch)
        }
    }
)(UserProfile)